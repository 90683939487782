import {Card, CardContent, Grid, TextField, Typography} from "@material-ui/core";
import apis from "apis";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import moment from "moment/moment";
import PropTypes from "prop-types";
import {useSnackbar} from "notistack";
import {OpeDialog} from "components";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    },

    mt: {
        marginTop: theme.spacing(2)
    },

}));

function PolicyInput({title, editItem, setEditItem, paramKey, isDate = false}) {
    const classes = useStyles();
    return (
        <Grid item xs={3}>
            <Typography gutterBottom variant="h6">{title}</Typography>
            <TextField
                className={classes.dialogInput}
                gutterBottom
                size="small"
                variant="outlined"
                type={isDate ? "date" : "text"}
                value={editItem[paramKey]}
                onChange={(e) => setEditItem({
                    ...editItem,
                    [paramKey]: e.target.value
                })}/>
        </Grid>
    )
}

function initItems() {
    return {
        afterDay: 3,
        arr: '',
        cabin: '',
        changeEditPercent: '',
        carrier: '',
        dep: '',
        fd: '',
        flightNo: '',
        fromDate: moment().format('YYYY-MM-DD'),
        lq: '',
        priority: 0,
        rangeSpace: '',
        enable: true,
        refundEditPercent: '',
        // seat: '',
        toDate: moment().add(3, 'months').format('YYYY-MM-DD'),
    }
}


const PolicyEditorFragment = (props) => {
    const classes = useStyles();
    const {isEdit, editItem, setEditItem} = props;
    return (<Card className={classes.mt}>
        <CardContent>
            <Grid
                container
                spacing={6}
                wrap="wrap"
            >
                {isEdit ?
                    <Grid item xs={3}>
                        <Typography gutterBottom variant="h6">策略名</Typography>
                        <Typography gutterBottom> {editItem.policyId}</Typography>
                    </Grid>
                    : <PolicyInput title="策略名" editItem={editItem} setEditItem={setEditItem}
                                   paramKey="policyId"/>
                }
            </Grid>
            <Grid
                container
                spacing={6}
                wrap="wrap"
            >
                <PolicyInput title="出发城市" editItem={editItem} setEditItem={setEditItem}
                             paramKey="dep"/>
                <PolicyInput title="到达城市" editItem={editItem} setEditItem={setEditItem}
                             paramKey="arr"/>
                <PolicyInput title="航班号" editItem={editItem} setEditItem={setEditItem}
                             paramKey="flightNo"/>
                <PolicyInput title="航司" editItem={editItem} setEditItem={setEditItem}
                             paramKey="carrier"/>
                <PolicyInput title="舱位" editItem={editItem} setEditItem={setEditItem}
                             paramKey="cabin"/>
                <PolicyInput title="起飞天数" editItem={editItem} setEditItem={setEditItem}
                             paramKey="afterDay"/>
                <PolicyInput title="政策优先级" editItem={editItem} setEditItem={setEditItem}
                             paramKey="priority"/>
            </Grid>
            <Grid
                container
                spacing={6}
                wrap="wrap"
            >
                <PolicyInput title="生效时间" editItem={editItem} setEditItem={setEditItem}
                             paramKey="fromDate" isDate={true}/>
                <PolicyInput title="结束时间" editItem={editItem} setEditItem={setEditItem}
                             paramKey="toDate" isDate={true}/>
                <PolicyInput title="退票调整(%)" editItem={editItem} setEditItem={setEditItem}
                             paramKey="refundEditPercent"/>
                <PolicyInput title="改期调整(%)" editItem={editItem} setEditItem={setEditItem}
                             paramKey="changeEditPercent"/>
            </Grid>
            <Grid
                container
                spacing={6}
                wrap="wrap"
            >
                <PolicyInput title="价格区间" editItem={editItem} setEditItem={setEditItem}
                             paramKey="rangeSpace"/>
                <PolicyInput title="区间返点" editItem={editItem} setEditItem={setEditItem} paramKey="fd"/>
                <PolicyInput title="区间留钱" editItem={editItem} setEditItem={setEditItem} paramKey="lq"/>
            </Grid>
        </CardContent>
    </Card>);
}

const PolicyEditorDialog = (props) => {
    const {editSource, orderSource, setRefresh, openDialog, setOpenDialog} = props;
    const [editItem, setEditItem] = useState(initItems());
    const [isEdit, setIsEdit] = useState(false);
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if (!!editSource) {
            setEditItem(editSource)
            setIsEdit(true)
        }
    }, [editSource])

    return (<OpeDialog
            title={isEdit ? "编辑策略" : "新增策略"}
            opeContent={
                <PolicyEditorFragment
                    isEdit={isEdit}
                    editItem={editItem}
                    setEditItem={setEditItem}
                />
            }
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            doDialog={
                () => {
                    apis.addOrUpdatePolicy({
                        ...editItem,
                        orderSource: orderSource.sourceId
                    }).then(res => {
                        if (res.status === 0) {
                            setRefresh(+new Date());
                            enqueueSnackbar(isEdit ? '修改成功' : '添加成功', {
                                variant: "success",
                                anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "center"
                                }
                            });
                            if (isEdit) {
                                setOpenDialog(false);
                            } else {
                                setEditItem(initItems())
                            }
                        } else {
                            enqueueSnackbar(res.message, {
                                variant: "error",
                                anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "center"
                                }
                            });
                        }
                    })
                }
            }
            okText="保存"
            okType="primary"/>
    );
}


PolicyEditorDialog.propTypes = {
    className: PropTypes.string,
    orderSource: PropTypes.object.isRequired,
    setRefresh: PropTypes.func.isRequired,
    openDialog: PropTypes.bool.isRequired,
    setOpenDialog: PropTypes.func.isRequired,
};


export default PolicyEditorDialog;