import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Button, Card, CardActions, CardContent, CardHeader, Dialog, DialogTitle, DialogActions } from "@material-ui/core";
import { Table } from 'views/common';
import apis from "apis";
import PropTypes from "prop-types";
import Toolbar from "./Toolbar";
import Pagination from "@material-ui/lab/Pagination";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SettingsIcon from "@material-ui/icons/Settings";
import ProductBundleEditorDialog from "./ProductBundleEditorDialog";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    tableButton: {
        marginRight: theme.spacing(1)
    },
    mt: {
        margin: theme.spacing(2)
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        fontSize: 14
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    dialogInput: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
}));


function ProductBundle(props) {
    const classes = useStyles();

    const [openDialog, setOpenDialog] = useState(false);
    const [openDelDialog, setOpenDelDialog] = useState(false);
    const [page, setPage] = useState(1);
    const [refresh, setRefresh] = useState(+new Date());
    const [keyword, setKeyword] = useState('');
    const [productBundleList, setProductBundleList] = React.useState([]);
    const [bundleProductList, setBundleProductList] = React.useState({});
    const [editSource, setEditSource] = useState({});


    useEffect(() => {
        apis.listProductBundle({

        }).then(res => {
            if (res.status === 0) {
                setProductBundleList(res.data.map(b => { b.xitemArray = JSON.parse(b.xitems); return b; }));
            }
        }).catch(console.log);
        apis.listBundleProduct({

        }).then(res => {
            if (res.status === 0) {
                setBundleProductList(res.data);
            }
        }).catch(console.log);
    }, [refresh])

    const showData = productBundleList.filter(item => {
        return JSON.stringify(item).includes(keyword);
    });

    return (
        <Card className={classes.mt}>
            <CardHeader title="OTA产品包" />
            <CardContent>
                <Toolbar bundleProductList={bundleProductList} setRefresh={setRefresh} onInputChange={
                    (value) => {
                        setKeyword(value);
                        setPage(1);
                    }
                } ></Toolbar>

                <div className={classes.content}>
                    <Table columns={[
                        {
                            label: 'ID',
                            key: 'id'
                        },
                        {
                            label: '备注',
                            key: 'note'
                        },
                        {
                            label: "生效航线",
                            key: 'depArrs'
                        },
                        {
                            label: "生效航司",
                            key: 'carriers'
                        },
                        // {
                        //     label: "生效舱",
                        //     key: 'cabins'
                        // },
                        {
                            label: "生效航班日期",
                            render: (item) => {
                                return (
                                    <p>
                                        {item.dateBegin}&nbsp;&nbsp;~&nbsp;&nbsp;{item.dateEnd}
                                    </p>
                                )
                            }
                        },
                        {
                            label: "优惠金额（元）",
                            key: 'ticketDiscountAmount'
                        },
                        {
                            label: "折叠",
                            render: (item) => {
                                return item.collapse ? '是' : '否'
                            }
                        },
                        {
                          label: "仅最低价",
                          render: (item) => {
                              return item.onlyLowestPrice ? '是' : '否'
                          }
                        },
                        {
                            label: "操作",
                            render: (item) => {
                                return (
                                    <>
                                        <Button
                                            startIcon={<SettingsIcon style={{ fontSize: 16 }} />}
                                            size="small"
                                            color="primary"
                                            className={classes.tableButton}
                                            onClick={() => {
                                                setEditSource(item);
                                                setOpenDialog(true)
                                            }}
                                            variant="contained">编辑</Button>
                                        <Button
                                            startIcon={<VisibilityIcon style={{ fontSize: 16 }} />}
                                            size="small"
                                            color="primary"
                                            className={classes.tableButton}
                                            onClick={() => {
                                              setEditSource(item);
                                              setOpenDelDialog(true)
                                            }}
                                            variant="contained">删除</Button>
                                    </>
                                )
                            }
                        }
                    ]}
                        data={showData}
                        collapse={true}
                        renderCollapse={(item) => (
                            <>
                                {JSON.parse(item.xitems).map(x => (
                                    <>
                                        <Grid container spacing={10} wrap="wrap">
                                            <Grid item xs={4}>
                                                类型：{x.type === 'INSURANCE' ? "保险" : x.type}
                                            </Grid>
                                            <Grid item xs={4}>
                                                名称：{x.data.productName}
                                            </Grid>
                                            <Grid item xs={4}>
                                                价格：{x.data.price}
                                            </Grid>
                                        </Grid>
                                    </>

                                ))}

                            </>
                        )}>
                    </Table>
                </div>
            </CardContent>
            <CardActions className={classes.actions}>
                <Pagination
                    count={Math.ceil(showData.length / 10) || 1}
                    page={page}
                    onChange={(event, page) => {
                        setPage(page);
                    }}
                    shape="rounded" />
            </CardActions>
            <ProductBundleEditorDialog
                bundleProductList={bundleProductList}
                isEdit={true}
                editSource={editSource}
                setRefresh={setRefresh}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
            />
            <Dialog
              open={openDelDialog}
              onClose={() => setOpenDelDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"确定删除?"}</DialogTitle>
              <DialogActions>
                <Button onClick={() => setOpenDelDialog(false)} color="primary">
                  取消
                </Button>
                <Button onClick={
                  () => {
                    setOpenDelDialog(false)
                    apis.deleteProductBundle({
                        id: editSource.id
                    }).then(res => {
                        if (res.status === 0) {
                            setRefresh(+new Date())
                        }
                    }).catch(console.log);
                  }
                } color="primary" autoFocus>
                  确定
                </Button>
              </DialogActions>
            </Dialog>
        </Card>

    )
}


ProductBundle.propTypes = {
    className: PropTypes.string,
};

export default ProductBundle;