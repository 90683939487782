import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {Button, Card, CardActions, CardContent, CardHeader} from "@material-ui/core";
import {Table} from 'views/common';
import apis from "apis";
import PropTypes from "prop-types";
import Toolbar from "./Toolbar";
import Pagination from "@material-ui/lab/Pagination";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SettingsIcon from "@material-ui/icons/Settings";
import PolicyEditorDialog from "./PolicyEditorDialog";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    tableButton: {
        marginRight: theme.spacing(1)
    },
    mt: {
        marginTop: theme.spacing(2)
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        fontSize: 14
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    dialogInput: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
}));


function Policy(props) {
    const classes = useStyles();
    const {orderSource} = props;
    const [openDialog, setOpenDialog] = useState(false);
    const [page, setPage] = useState(1);
    const [refresh, setRefresh] = useState(+new Date());
    const [keyword, setKeyword] = useState('');
    const [policyList, setPolicyList] = React.useState([]);
    const [editSource, setEditSource] = useState({});


    useEffect(() => {
        apis.listPolicy({
            orderSourceId: orderSource.sourceId
        }).then(res => {
            if (res.status === 0) {
                setPolicyList(res.data);
            }
        }).catch(console.log);
    }, [orderSource, refresh])

    const showData = policyList.filter(item => {
        return JSON.stringify(item).includes(keyword);
    });

    return (
        <Card className={classes.mt}>
            <CardHeader title="调价政策"/>
            <CardContent>
                <Toolbar setRefresh={setRefresh} onInputChange={
                    (value) => {
                        setKeyword(value);
                        setPage(1);
                    }
                } orderSource={orderSource}></Toolbar>

                <div className={classes.content}>
                    <Table columns={[
                        {
                            label: '政策ID',
                            key: 'policyId'
                        },
                        {
                            label: '生效状态',
                            render: (item) => {
                                return item.enable ? "启用" : "禁用"
                            }
                        },
                        {
                            label: "行程(出发/到达)",
                            render: (item) => {
                                return (
                                    <p>
                                        {item.dep}/{item.arr}
                                    </p>
                                )
                            }
                        },
                        {
                            label: "航班(航司/航班/仓位)",
                            render: (item) => {
                                return (
                                    <p>
                                        {item.carrier}/{item.flightNo}/{item.cabin}
                                    </p>
                                )
                            }
                        },
                        {
                            label: "起飞天数",
                            key: 'afterDay'
                        },
                        {
                            label: "生效时间",
                            render: (item) => {
                                return (
                                    <p>
                                        {item.fromDate}&nbsp;&nbsp;|&nbsp;&nbsp;{item.toDate}
                                    </p>
                                )
                            }
                        },
                        {
                            label: "手续费调整百分比（退票 | 改期）",
                            render: (item) => {
                                return (
                                    <p>
                                        {item.refundEditPercent}%&nbsp;&nbsp;|&nbsp;&nbsp;{item.changeEditPercent}%
                                    </p>
                                )
                            }
                        },
                        {
                            label: "改价规则",
                            render: (item) => {
                                return (
                                    <div>
                                        <div>{item.rangeSpace}</div>
                                        <div>{item.fd}</div>
                                        <div>{item.lq}</div>
                                    </div>
                                )
                            }
                        },
                        {
                            label: "政策优先级",
                            key: 'priority'
                        },
                        {
                            label: "操作",
                            render: (item) => {
                                return (
                                    <>
                                        <Button
                                            startIcon={<SettingsIcon style={{fontSize: 16}}/>}
                                            size="small"
                                            color="primary"
                                            className={classes.tableButton}
                                            onClick={() => {
                                                setEditSource(item);
                                                setOpenDialog(true)
                                            }}
                                            variant="contained">编辑</Button>
                                        <Button
                                            startIcon={<VisibilityIcon style={{fontSize: 16}}/>}
                                            size="small"
                                            color="primary"
                                            className={classes.tableButton}
                                            onClick={() => {
                                                apis.addOrUpdatePolicy({
                                                    ...item,
                                                    enable: !item.enable
                                                }).then(res => {
                                                    if (res.status === 0) {
                                                        setRefresh(+new Date())
                                                    }
                                                }).catch(console.log);
                                            }}
                                            variant="contained">{item.enable ? "禁用" : "启用"}</Button>
                                    </>
                                )
                            }
                        }
                    ]}
                           data={showData}>
                    </Table>
                </div>
            </CardContent>
            <CardActions className={classes.actions}>
                <Pagination
                    count={Math.ceil(showData.length / 10) || 1}
                    page={page}
                    onChange={(event, page) => {
                        setPage(page);
                    }}
                    shape="rounded"/>
            </CardActions>
            <PolicyEditorDialog
                editSource={editSource}
                orderSource={orderSource}
                setRefresh={setRefresh}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
            />
        </Card>

    )
}


Policy.propTypes = {
    className: PropTypes.string,
    orderSource: PropTypes.object.isRequired
};

export default Policy;