import React, {useEffect, useState} from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/styles";
import {useSnackbar} from "notistack";

import EditIcon from "@material-ui/icons/Edit";
import {Button, Card, CardContent, CardHeader} from "@material-ui/core";

import apis from "apis";
import CodeMirror from "@uiw/react-codemirror";
import {StreamLanguage} from "@codemirror/language";
import {groovy} from "@codemirror/legacy-modes/mode/groovy";

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 0
    },
    nameContainer: {
        display: "flex",
        alignItems: "center"
    },
    avatar: {
        marginRight: theme.spacing(2)
    },
    actions: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        justifyContent: "center"
    },
    tableButton: {
        marginRight: theme.spacing(1)
    },
    mt: {
        marginTop: theme.spacing(2)
    },
    inputItem: {
        width: "100%"
    }, pos: {
        marginBottom: 12
    }, inline: {
        display: "inline-block"
    }
}));

const demoScript = `
// 当前您还没有进行订单源配置
// 初次接入，请按照如下模版配置您的订单源接入参数
// 理解参数概念后可以删除注释

package com.kuaguo.gsq.flight.supplier.dsl.upstream

config {
    // 当前渠道是否开放售卖
    open true

    // 和上游约定的API key
    apiKey "dddsdfadasf"

    // 当前渠道(上游)是否开放调价政策功能
    adjustPolicy true

    // 当前渠道使用的代理商，不配置则默认所有
    useAgents "FliggyDaigou"

    // B2B 飞猪
    ext "taobaoId", "default"
    ext "taobaoAppKey", "default"
    ext "taobaoAppSecret", "default"
}
`

const ConfigFragment = props => {
    const {enqueueSnackbar} = useSnackbar();
    const {className, orderSource, ...rest} = props;
    const classes = useStyles();
    const initScript = orderSource.script || demoScript;
    const [pluginCode, setPluginCode] = useState(initScript);
    const [editCode, setEditCode] = useState(initScript);
    const [refresh, setRefresh] = useState(+new Date());

    useEffect(() => {
        apis.orderSourceDetail({orderSourceId: orderSource.sourceId})
            .then(res => {
                if (res.status === 0) {
                    setPluginCode(res.data.script || initScript);
                    setEditCode(res.data.script || initScript);
                } else {
                    enqueueSnackbar(res.message.substring(0, 50), {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                }
            }).catch((e) => {
            enqueueSnackbar(e.message, {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center"
                }
            });
        });
    }, [enqueueSnackbar, orderSource, refresh]);


    let updateDSL = () => {
        apis.updateOrderSourceDSL({
            orderSourceId: orderSource.sourceId,
            scriptContent: editCode
        })
            .then(res => {
                if (res.status === 0) {
                    setRefresh(+new Date());
                } else {
                    console.log(res.message);
                    enqueueSnackbar(res.message.substring(0, 300), {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                }
            }).catch((e) => {
            enqueueSnackbar(e.message, {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center"
                }
            });
        });
    };

    return (
        <>
            <Card
                {...rest}
                className={clsx(classes.root, className)}
            >
                <CardHeader
                    title="订单源配置"
                    action={
                        (editCode && pluginCode !== editCode) ?
                            <Button
                                startIcon={<EditIcon style={{fontSize: 16}}/>}
                                size="small"
                                color="primary"
                                className={classes.tableButton}
                                onClick={() => {
                                    updateDSL();
                                }}
                                variant="contained">保存修改</Button> : <></>
                    }
                >
                </CardHeader>

                <CardContent className={classes.content}>
                    <CodeMirror
                        height="600px"
                        value={pluginCode}
                        onChange={(value) => {
                            setEditCode(value);
                        }}
                        extensions={[StreamLanguage.define(groovy)]}
                    />
                </CardContent>
            </Card>
        </>
    );
};

ConfigFragment.propTypes = {
    className: PropTypes.string,
    orderSource: PropTypes.object.isRequired
};

export default ConfigFragment;
