import React, {useEffect, useState} from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/styles";
import {useSnackbar} from "notistack";

import EditIcon from "@material-ui/icons/Edit";
import {Button, Card, CardContent, CardHeader} from "@material-ui/core";

import apis from "apis";
import CodeMirror from "@uiw/react-codemirror";
import {StreamLanguage} from "@codemirror/language";
import {groovy} from "@codemirror/legacy-modes/mode/groovy";

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 0
    },
    nameContainer: {
        display: "flex",
        alignItems: "center"
    },
    avatar: {
        marginRight: theme.spacing(2)
    },
    actions: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        justifyContent: "center"
    },
    tableButton: {
        marginRight: theme.spacing(1)
    },
    mt: {
        marginTop: theme.spacing(2)
    },
    inputItem: {
        width: "100%"
    }, pos: {
        marginBottom: 12
    }, inline: {
        display: "inline-block"
    }
}));

const demoScript =`
// 初次接入，请按照如下模版配置您的代理商接入参数
// 理解参数概念后可以删除注释

// 基础配置
basic {
    // 当前代理商是否开启，为代理商生效总开关
    enable = true
    // 开启报价avCheck，如果开启，那么在报价之前平台将会使用黑屏校验仓位，避免报出无效价格
    avCheck = true
    // 开启报价缓存，目前对于单程直飞（含经停）提供报价缓存能力，本开关开启后，系统将会根据自己的用户流量情况执行报价预抓
    // 请注意一般情况报价缓存开启后需要同步开启avCheck
    priceCache = true

    // 和代理商约定的APIKey，对API访问进行签名使用
    apiKey = "aljiuehuxskjxhe0ak7sd"


    /**
     * 确定使用智行协议、我们的协议
     *  智行协议：兼容智行的接口格式，对于已经在智行平台上线的代理商，可以几乎零开发完成到夸果平台接入。
     *  夸果协议：没有历史包袱，数据格式更加清晰，报文格式更加贴近逻辑思路。新代理商更加建议使用夸果协议
     */
    //apiProtocol = "kuaguo"
    apiProtocol = "zhixing"
    // 下面是代理商运价直连方式提供的接口组，用于我们和代理商的系统进行交互
    // 接口组规则满足智行接口文档规则
    apiSearch = "https://www.test-agent/flight/search"
    apiBooking = "https://www.test-agent/flight/booking"
    apiCreateOrder = "https://www.test-agent/flight/createOrder"
    apiPayValid = "https://www.test-agent/flight/payValid"
    apiPayNotify = "https://www.test-agent/flight/payNotify"
}

// 对于开启了报价缓存的的代理商，本段描述报价缓存规则
priceCacheExpire {
    // 默认规则
    if (takeoffDay > 7) {
        expire 24 * 60
    } else if (takeoffDay > 3) {
        expire 8 * 60
    } else {
        expire 60
    }

    // 之后的特殊规则
    if (carrier == 'CZ' && cabin == 'Y' && dep == 'CTU') {
        if (takeoffDay > 5) {
            expire 8 * 60
        } else {
            expire 50
        }
    }

}


// 该代理商开放售卖规则
openSale {
    // 配置支持的航司
    carriers 'CZ,MU,CA,HU'

    // 支持开放售卖时间
    openDays 3
    //或者 openHour 7

    // 配置支持的上游订单渠道
    sources 'ZhiFuBaoMiniProgram,WeiXinMiniProgram'

    // 该代理商售卖的航线
    trips "PEK-SHA,CTU-KMG,CTU"
}

/*

// 直接使用脚本规则，更加灵活的控制开放售卖规则。
openSaleScript {
    if (carrier in ['CZ', 'MU']) {
        return false
    }
    return source == 'ZhiFuBaoMiniProgram'
}
*/


// 配置退改签规则，代理商退改签数据缺失时，使用这个退改签规则兜底
// 航司，仓位
tqq "CZ", "Y", "ADT", true, true, false, "20-168-40-48-70-4-90", "20-168-40-48-70-4-90"
tqq "3U", "Z", "CHD", true, true, false, "20-168-40-48-70-4-90", "20-168-40-48-70-4-90"

// 使用json的方式配置退改签规则，这这种配置方式更加形象
tgqJSON """
[{
    carrier:"CZ"
    cabin:"Y"
    passengerType:"ADT"
    canRefund:true
    canCharge:true
    allowChange:false
    returnRule:"5-72-10-4-15"
    changeRule:"0-168-5-4-10"
},{
    carrier:"CZ"
    cabin:"Y"
    passengerType:"ADT"
    canRefund:true
    canCharge:true
    allowChange:false
    returnRule:"5-72-10-4-15"
    changeRule:"0-168-5-4-10"
}]
"""


// 配置兜底的行李额
luggage {
    carrier = "CZ"
    cabinClass = "Y"
    subclass = "Y,B,B1,M,H,K,L,P,Q,G,V,U,Z,X,N,N1,N,N2,J,S,R,T,E,Z1,X1,R1,R2,E1,E2"
    passengerType = "ADU"
    freeLuggageAmount = 20
    freeLuggageAmount = 0
    carryOnLuggageMaxAmount = 0
    luggageType = 1
    carryOnMaxWeight = 0
    carryOnMaxSize = 0
    checkinMaxWeight = 0
    checkinMaxSize = 0
    checkinMaxAmount = 0
    specifiedDesc = "婴儿无免费行李额,如需携带婴儿车或摇篮须按托运行李办理. "
}
`

const ConfigFragment = props => {
    const {enqueueSnackbar} = useSnackbar();
    const {className, agent, ...rest} = props;
    const classes = useStyles();
    const initScript = agent.script || demoScript;
    const [pluginCode, setPluginCode] = useState(initScript);
    const [editCode, setEditCode] = useState(initScript);
    const [refresh, setRefresh] = useState(+new Date());

    useEffect(() => {
        apis.agentGet()
            .then(res => {
                if (res.status === 0) {
                    setPluginCode(res.data.script || initScript);
                    setEditCode(res.data.script || initScript);
                } else {
                    enqueueSnackbar(res.message.substring(0, 50), {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                }
            }).catch((e) => {
            enqueueSnackbar(e.message, {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center"
                }
            });
        });
    }, [enqueueSnackbar, agent, refresh]);


    let updateAgentDSL = () => {
        apis.updateAgentDSL({
            agent: agent.agent,
            scriptContent: editCode
        })
            .then(res => {
                if (res.status === 0) {
                    setRefresh(+new Date());
                } else {
                    console.log(res.message);
                    enqueueSnackbar(res.message.substring(0, 300), {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        }
                    });
                }
            }).catch((e) => {
            enqueueSnackbar(e.message, {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center"
                }
            });
        });
    };

    return (
        <>
            <Card
                {...rest}
                className={clsx(classes.root, className)}
            >
                <CardHeader
                    title="代理商配置"
                    action={
                        (editCode && pluginCode !== editCode) ?
                            <Button
                                startIcon={<EditIcon style={{fontSize: 16}}/>}
                                size="small"
                                color="primary"
                                className={classes.tableButton}
                                onClick={() => {
                                    updateAgentDSL();
                                }}
                                variant="contained">保存修改</Button> : <></>
                    }
                >
                </CardHeader>

                <CardContent className={classes.content}>
                    <CodeMirror
                        height="600px"
                        value={pluginCode}
                        onChange={(value) => {
                            setEditCode(value);
                        }}
                        extensions={[StreamLanguage.define(groovy)]}
                    />
                </CardContent>
            </Card>
        </>
    );
};

ConfigFragment.propTypes = {
    className: PropTypes.string,
    agent: PropTypes.object.isRequired
};

export default ConfigFragment;
