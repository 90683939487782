import React, {useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {SearchInput} from 'components';
import {Button} from '@material-ui/core';

import clsx from 'clsx';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import PolicyEditorDialog from "./PolicyEditorDialog";

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    searchInput: {},
    dialog: {
        width: theme.spacing(60)
    },
    dialogInput: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
}));

const Toolbar = props => {
    const {className, onInputChange, setRefresh, orderSource, ...rest} = props;
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);
    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <div className={classes.row}>
                <SearchInput
                    className={classes.searchInput}
                    onChange={(v) => onInputChange(v)}
                    placeholder="请输入关键词进行查询"
                />
                <span className={classes.spacer}/>
                <Button
                    startIcon={<QueuePlayNextIcon/>}
                    color="primary"
                    variant="contained"
                    onClick={() => setOpenDialog(true)}
                >
                    新增策略
                </Button>

            </div>
            <PolicyEditorDialog
                orderSource={orderSource}
                setRefresh={setRefresh}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}/>
        </div>
    );
};

export default Toolbar;
