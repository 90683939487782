import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {useHistory, useParams} from "react-router-dom";
import {Button, Card, CardContent, CardHeader, Paper, Tab, Tabs, Typography} from "@material-ui/core";

import SourceScriptConfig from "./ScriptConfig";
import Policy from "./Policy/Policy";
import apis from "apis";
import {Loading} from "components";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    }
}));

function TabPanel(props) {
    const {children, value, index} = props;
    return value === index ? children : null;
}

function OrderSourceDashBoard() {
    const params = useParams();
    const classes = useStyles();
    const history = useHistory();
    const [orderSource, setOrderSource] = useState({});

    let initValue = Number(localStorage.getItem("orderSourceTabIndex-" + params.id)) || 0;
    const [value, setValue] = useState(initValue);
    useEffect(() => {
        if (params.id) {
            localStorage.setItem("orderSourceTabIndex-" + params.id, value + "");
        }
    }, [value, params.id])



    useEffect(() => {
        apis.orderSourceDetail({orderSourceId: params.id})
            .then(res => {
                if (res.status === 0) {
                    setOrderSource(res.data);
                } else {
                    // 如果走到了一个不存在的项目下，那么需要404
                    history.push("/not-found");
                }
            })
            .catch(e => {
                console.log(e);
            });
    }, [history, params.id]);

    if (!params.id) {
        // 如果走到了一个不存在的项目下，那么需要404
        history.push("/not-found");
    }


    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <Card>
                    <CardHeader title={`订单源详情: ` + orderSource.sourceId}
                                subheader={orderSource.sourceDesc}
                                action={(
                                    <Button
                                        startIcon={<ArrowBackIcon style={{fontSize: 16}}/>}
                                        size="small"
                                        color="primary"
                                        className={classes.tableButton}
                                        onClick={() => {
                                            history.go(-1)
                                        }}
                                    >
                                        返回
                                    </Button>
                                )}
                    />
                    <CardContent>
                        <Tabs
                            value={value}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={(event, val) => {
                                setValue(val);
                            }}
                        >
                            <Tab label="订单源配置"/>
                            <Tab label="调价政策"/>
                        </Tabs>
                        {
                            orderSource.sourceId ?
                                (<div className={classes.content}>
                                    <TabPanel value={value} index={0}>
                                        <SourceScriptConfig orderSource={orderSource} className={classes.content}/>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                       <Policy orderSource={orderSource} className={classes.content}/>
                                    </TabPanel>

                                </div>) :
                                (<Loading/>)
                        }
                    </CardContent>
                </Card>

            </div>
        </div>
    );
}

export default OrderSourceDashBoard;
