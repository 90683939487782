import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Pagination from '@material-ui/lab/Pagination';
import {Button, Card, CardActions, CardContent, TextField, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {Table} from 'views/common';
import {useSnackbar} from 'notistack';

import apis from 'apis';
import SettingsIcon from "@material-ui/icons/Settings";
import {OpeDialog} from "components";

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 0
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(2)
    },
    actions: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        justifyContent: 'center'
    },
    tableButton: {
        marginRight: theme.spacing(1)
    },
}));

const DataTable = props => {
    const {enqueueSnackbar} = useSnackbar();
    const {className, data, total, rowsPerPage, pageState, setRefresh, onAgentSelected, selectedAgent, ...rest} = props;
    const [page, setPage] = pageState;

    const [editAgent, setEditAgent] = useState({});
    const [openDialog, setOpenDialog] = useState(false);

    const classes = useStyles();

    const [selected, setSelected] = useState([]);

    useEffect(() => {
        console.log("selectedAgent :" + selectedAgent);
        if (selectedAgent && data) {
            let find = data.find((it) => it.agent === selectedAgent);
            if (find) {
                setSelected([find.id])
            }
        }

    }, [selectedAgent, data])

    const handlePageChange = (event, page) => {
        setPage(page);
    };
    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardContent className={classes.content}>
                <Table
                    checkedKey={"id"}
                    checkbox={!!onAgentSelected}
                    checked={selected}
                    handleSelectOne={(event, item) => {
                        if (selected.length > 0 && selected[0] === item) {
                            setSelected([])
                            !!onAgentSelected && onAgentSelected(null)
                        } else {
                            setSelected([item])
                            !!onAgentSelected && onAgentSelected(data.find((it) => it.id === item))
                        }
                    }}
                    data={data}
                    columns={[
                        {
                            label: 'ID',
                            key: 'id'
                        },
                        {
                            label: '代理商',
                            key: 'agent'
                        },
                        {
                            label: '描述',
                            key: 'agentDesc'
                        }
                        , {
                            label: '操作',
                            render: (item) => (
                                <>

                                    <Button
                                        startIcon={<SettingsIcon style={{fontSize: 16}}/>}
                                        size="small"
                                        color="primary"
                                        className={classes.tableButton}
                                        onClick={() => {
                                            setEditAgent(item);
                                            setOpenDialog(true);
                                        }}
                                        variant="contained">编辑</Button>
                                </>
                            )
                        }
                    ]}
                />
            </CardContent>
            <CardActions className={classes.actions}>
                <Pagination
                    count={Math.ceil(total / rowsPerPage) || 1}
                    page={page}
                    onChange={handlePageChange}
                    shape="rounded"/>
            </CardActions>
            <OpeDialog
                title="修改代理商描述"
                opeContent={(
                    <>

                        <Typography gutterBottom variant="h6">代理商描述</Typography>
                        <TextField
                            className={classes.dialogInput}
                            size="small"
                            variant="outlined"
                            value={editAgent.agentDesc}
                            onChange={(e) => {
                                setEditAgent({
                                    ...editAgent,
                                    agentDesc: e.target.value
                                })
                            }}/>

                    </>
                )}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                doDialog={() => {
                    return apis.updateAgentDesc({
                        agent: editAgent.agent,
                        agentDesc: editAgent.agentDesc
                    }).then(res => {
                        if (res.status === 0) {
                            setRefresh(+new Date());
                            return;
                        }
                        enqueueSnackbar(res.msg, {
                            variant: 'error',
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            },
                        });
                    }).catch((e) => {
                        enqueueSnackbar(e.msg, {
                            variant: 'error',
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            },
                        })
                    });
                }}
                okText="保存"
                okType="primary"/>
        </Card>
    );
};

DataTable.propTypes = {
    className: PropTypes.string,
    data: PropTypes.array.isRequired,
    onAgentSelected: PropTypes.func
};

export default DataTable;
