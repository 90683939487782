import {Button} from "@material-ui/core";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import apis from "apis";
import {Table} from "views/common";
import React from "react";
import PropTypes from "prop-types";

const Booking = (props) => {
    const {productList, onBookingResponse} = props;

    return (
        <Table
            data={productList}
            columns={[
                {
                    label: '出发',
                    key: 'dep'
                }, {
                    label: '到达',
                    key: 'arr'
                },
                {
                    label: '航线',
                    key: 'tripId'
                }, {
                    label: '仓位/价格',
                    key: 'cabin'
                }, {
                    label: '操作',
                    render: (item) => (
                        <>

                            <Button
                                startIcon={<MotorcycleIcon style={{fontSize: 16}}/>}
                                size="small"
                                color="primary"
                                onClick={() => {
                                    apis.flightBooking({
                                        "productId": item.productId,
                                        "source":"agentDebug",
                                        "forceTrace": true
                                    }).then((res) => {
                                        onBookingResponse(res)
                                    })
                                }}
                                variant="contained">Booking</Button>
                        </>
                    )
                }
            ]}
        />
    );
}

Booking.propTypes = {
    productList: PropTypes.array.isRequired,
    onBookingResponse: PropTypes.func.isRequired
}

export default Booking;