import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';

import {Table} from './components';
import SearchFilter from './SearchFilter'
import apis from 'apis';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    }
}));

const SysLog = () => {
    const classes = useStyles();

    const [showData, setShowData] = useState([]);
    const [total, setTotal] = useState(0);


    const [refresh, setRefresh] = useState(+new Date());

    const [searchParams, setSearchParams] = useState({
      keyword: null,
      pageNo: 1,
  });

    useEffect(() => {
        const getData = () => {

            apis.sysLogSearch({...searchParams})
                .then(res => {
                    if (res.status === 0) {
                        setShowData(res.data.records);
                        setTotal(res.data.total);
                    }
                }).catch(e => console.log(e));
        }
        getData();
    }, [refresh]);


    return (
        <div className={classes.root}>
            <SearchFilter
                searchForm={searchParams}
                setSearchForm={setSearchParams}
                setRefresh={setRefresh}
            />
            <div className={classes.content}>
                <Table
                    data={showData}
                    total={total}
                    pageState={[searchParams.pageNo, (page) => {
                        setSearchParams({...searchParams, pageNo: page})
                    }]}
                    setRefresh={setRefresh}/>
            </div>
        </div>
    );
};

export default SysLog;
