import {Button, Card, CardContent, CardHeader, Divider, Grid, Typography, CardActions} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React from "react";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/styles";

import {STATUS} from "../OrderList/components/Table/Table";
import apis from 'apis';
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
    tableButton: {
        marginRight: theme.spacing(1)
    }
}));

function sourceChannel(ext) {
    if (ext) {
        var extJson = JSON.parse(ext);

        var channel = extJson.order?.channel;
        if (channel === 'user') {
            return '用户';
        } else if (channel === 'agent') {
            return '代理商(' + extJson.order.agentName + ')';
        } else {
            return ''
        }
    }
}

const BaseFragment = (props) => {
    const {order, reload} = props;
    const classes = useStyles();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    return (
        <Card>
            <CardHeader title={`订单概览`}
                        action={
                            <CardActions className={classes.actions}>
                                {/* if order's status is USER_PAY or USER_PAY_AGENT_NOTIFY_FAILED, display this button */}

                                {(order.orderInfo.orderStatus === 'USER_PAY' || order.orderInfo.orderStatus === 'USER_PAY_AGENT_NOTIFY_FAILED') && (
                                    <Button
                                        size="small"
                                        color="primary"
                                        className={classes.tableButton}
                                        onClick={() => {
                                            // TODO: implement this function
                                            apis.setFailed({orderNo: order.orderInfo.orderNo}).then(res => {
                                                if (res.status === 0) {
                                                    reload();
                                                    return;
                                                }
                                                enqueueSnackbar(res.msg, {
                                                    variant: 'error',
                                                    anchorOrigin: {
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                    },
                                                });
                                            }).catch((e) => {
                                                enqueueSnackbar(e.msg, {
                                                    variant: 'error',
                                                    anchorOrigin: {
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                    },
                                                })
                                            });
                                        }}
                                    >
                                        关单退款(先确认代理商已退款！)
                                    </Button>
                                )}
                                <Button
                                    startIcon={<ArrowBackIcon style={{fontSize: 16}}/>}
                                    size="small"
                                    color="primary"
                                    className={classes.tableButton}
                                    onClick={() => {
                                        history.go(-1)
                                    }}
                                >
                                    返回
                                </Button>
                            </CardActions>
                        }
            />
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <Typography variant="inherit">主订单号：</Typography>{order.orderInfo.orderNo}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="inherit">上游订单号：</Typography>{order.orderInfo.sourceOrderNo}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography
                            variant="inherit">代理商订单号：</Typography>{order.orderInfo.agentOrderNoV2 ? order.orderInfo.agentOrderNoV2 : order.orderInfo.agentOrderNo}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="inherit">代理商ID：</Typography>{order.orderInfo.agent}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="inherit">订单源：</Typography>{order.orderInfo.source}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="inherit">订单状态：</Typography>{STATUS[order.orderInfo.orderStatus]}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="inherit">创建订单时间：</Typography>{order.orderInfo.orderTime}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography
                            variant="inherit">上游渠道：</Typography>{sourceChannel(order.orderInfo.sourceExtInfo)}
                    </Grid>
                </Grid>
                <Divider style={{margin: '20px 0'}}/>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <Typography variant="inherit">出发：</Typography>{order.orderInfo.depName}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="inherit">到达：</Typography>{order.orderInfo.arrName}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="inherit">出发日期：</Typography>{order.orderInfo.depDate}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="inherit">返程日期：</Typography>{order.orderInfo.retDate}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="inherit">订单总价：</Typography>{order.orderInfo.orderOutboundRealPrice}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography
                            variant="inherit">成人销售价（多航段，含税）：</Typography>{order.orderInfo.adultPrice}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography
                            variant="inherit">儿童销售价（多航段，含税）：</Typography>{order.orderInfo.childPrice}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography
                            variant="inherit">婴儿销售价（多航段，含税）：</Typography>{order.orderInfo.infantPrice}
                    </Grid>
                </Grid>
                <Divider style={{margin: '20px 0'}}/>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <Typography variant="inherit">联系人：</Typography>{order.orderInfo.contactName}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="inherit">电话：</Typography>{order.orderInfo.contactPhone}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography
                            variant="inherit">国家（地区）：</Typography>{order.orderInfo.contactCountryTypography}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="inherit">邮箱：</Typography>{order.orderInfo.contactEmail}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography
                            variant="inherit">地址：</Typography>{order.orderInfo.contactAddress}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default BaseFragment;