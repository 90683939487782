import { Button, Grid, TextField, Typography } from "@material-ui/core";
import apis from "apis";
import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import PropTypes from "prop-types";

const Search = (props) => {
  const { onSearchResponse } = props;

  const [searchForm, setSearchForm] = useState({
    "dep": "KWE",
    "arr": "SHA",
    "depDate": moment(new Date()).add(30, "day").format('YYYY-MM-DD'),
    "returnDate": ''
  })

  useEffect(() => {
    let temp = { ...searchForm }
    try {
      const item = localStorage.getItem("agentDebugParam");
      temp = {
        ...temp,
        ...JSON.parse(item),
        "depDate": moment(new Date()).add(30, "day").format('YYYY-MM-DD'),
      }
    } catch (e) { }
    setSearchForm(temp)
  }, [])

  useEffect(() => {
    localStorage.removeItem("agentDebugParam");
    localStorage.setItem("agentDebugParam", JSON.stringify(searchForm));
  }, [searchForm])

  return (
    <div style={{ padding: 20 }}>
      <Typography variant="h2">
        Search
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <TextField
            label="出发城市"
            variant="outlined"
            color="secondary"
            value={searchForm.dep}
            onChange={e => {
              setSearchForm({
                ...searchForm,
                dep: e.target.value
              })
            }}
            style={{ width: '100%', marginTop: 20 }}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            label="到达城市"
            variant="outlined"
            color="secondary"
            value={searchForm.arr}
            onChange={e => {
              setSearchForm({
                ...searchForm,
                arr: e.target.value
              })
            }}
            style={{ width: '100%', marginTop: 20 }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <TextField
            label="出发日期"
            variant="outlined"
            color="secondary"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={searchForm.depDate}
            onChange={e => {
              setSearchForm({
                ...searchForm,
                depDate: e.target.value
              })
            }}
            style={{ width: '100%', marginTop: 20, marginBottom: 20 }}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            label="返程日期"
            variant="outlined"
            color="secondary"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={searchForm.returnDate}
            onChange={e => {
              setSearchForm({
                ...searchForm,
                returnDate: e.target.value
              })
            }}
            style={{ width: '100%', marginTop: 20, marginBottom: 20 }}
          />
        </Grid>
      </Grid>
      <Button variant="contained" color='primary' onClick={() => {
        apis.flightSearch({
          ...searchForm,
          "source":"agentDebug",
          "forceTrace": true
        }).then((res) => {
          onSearchResponse(res, searchForm)
        })
      }}>Search</Button>
    </div>
  );
}

Search.propTypes = {
  onSearchResponse: PropTypes.func.isRequired,
}

export default Search;