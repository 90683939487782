import React, {useContext, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Pagination from '@material-ui/lab/Pagination';
import DirectionsRailwayIcon from '@material-ui/icons/DirectionsRailway';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import SettingsIcon from '@material-ui/icons/Settings';
import {makeStyles} from '@material-ui/styles';
import {Table} from 'views/common';
import {useSnackbar} from 'notistack';
import {useHistory} from 'react-router-dom';
import {
    Card,
    CardActions,
    CardContent,
    Button,
} from '@material-ui/core';
import {AppContext} from 'adapter';
import moment from 'moment';

import apis from 'apis';
import {OpeDialog} from "components";
import AgentList from '../../../Agent/AgentList'
import SourceList from '../../../OrderSource/List'

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 0
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(2)
    },
    actions: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        justifyContent: 'center'
    },
    tableButton: {
        marginRight: theme.spacing(1)
    },
    dialogInput: {
        width: '100%'
    }
}));

const DataTable = props => {
    const {setUser} = useContext(AppContext);
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const {className, data, total, rowsPerPage, pageState, setRefresh, ...rest} = props;
    const [page, setPage] = pageState;

    const [openDialog, setOpenDialog] = useState(false);
    const [opItem, setOpItem] = useState({})

    const [openSourceDialog, setOpenSourceDialog] = useState(false);

    const classes = useStyles();

    const handlePageChange = (event, page) => {
        setPage(page);
    };

    const doLogin = (item) => {
        apis.login({
            userName: item.userName,
            password: item.passwd,
        }).then(res => {
            if (res.status !== 0) {
                enqueueSnackbar(res.message, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                });
            } else {
                apis.setStore({...res.data, mock: true}, "Gsq-USER-MOCK");
                setUser({
                    ...res.data,
                    mock: true,
                    time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
                });
                history.push('/');
            }
        });
    }

    const grantAdmin = (item) => {
        apis.grantAdmin({
            userName: item.userName,
            isAdmin: !item.isAdmin,
        }).then(res => {
            if (res.status !== 0) {
                enqueueSnackbar(res.errorMessage || res.message, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                });
            } else {
                setRefresh(+new Date());
            }
        });
    };

    const grantAgentDeveloper = (item) => {
        apis.grantDeveloper({
            userName: item.userName,
            isDevelop: !item.agentDeveloper,
        }).then(res => {
            if (res.status !== 0) {
                enqueueSnackbar(res.message, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                });
            } else {
                setRefresh(+new Date());
            }
        });
    };

    const bindAgent = (userName, agent) => {
        apis.addUserToAgentGroup({
            userName: userName,
            agent: agent
        }).then(res => {
            if (res.status !== 0) {
                enqueueSnackbar(res.message, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                });
            } else {
                setRefresh(+new Date());
                setOpenDialog(false)
            }
        });
    }

    const bindSource = (userName, source) => {
      apis.bindSource({
          userName: userName,
          source: source
      }).then(res => {
          if (res.status !== 0) {
              enqueueSnackbar(res.message, {
                  variant: 'error',
                  anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'center',
                  },
              });
          } else {
              setRefresh(+new Date());
              setOpenSourceDialog(false)
          }
      });
  }

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardContent className={classes.content}>
                <Table
                    data={data}
                    columns={[
                        {
                            label: '账号',
                            key: 'userName'
                        }, {
                            label: '密码',
                            key: 'passwd'
                        },
                        {
                            label: '所属代理商',
                            key: 'agent'
                        },
                        {
                            label: '所属订单源',
                            key: 'source'
                        }, {
                            label: '管理员',
                            render: (item) => (
                                item.isAdmin ? (<p>是</p>) : (<p>否</p>)
                            )
                        }, {
                            label: '代理商管理员',
                            render: (item) => (
                                item.agentDeveloper ? (<p>是</p>) : (<p>否</p>)
                            )
                        }, {
                            label: '操作',
                            render: (item) => (
                                <>
                                    <Button
                                        startIcon={<DirectionsRailwayIcon style={{fontSize: 16}}/>}
                                        size="small"
                                        color="primary"
                                        className={classes.tableButton}
                                        onClick={() => doLogin(item)}
                                        variant="contained">登录</Button>
                                    <Button
                                        startIcon={<SupervisorAccountIcon style={{fontSize: 16}}/>}
                                        size="small"
                                        color="primary"
                                        className={classes.tableButton}
                                        onClick={() => grantAdmin(item)}
                                        variant="contained">{item.isAdmin ? "移除管理员" : "升级管理员"}</Button>
                                    <Button
                                        startIcon={<MotorcycleIcon style={{fontSize: 16}}/>}
                                        size="small"
                                        color="primary"
                                        className={classes.tableButton}
                                        onClick={() => grantAgentDeveloper(item)}
                                        variant="contained">{item.agentDeveloper ? "移除代理商管理员" : "设置代理商管理员"}</Button>
                                    <Button
                                        startIcon={<SettingsIcon style={{fontSize: 16}}/>}
                                        size="small"
                                        color="primary"
                                        className={classes.tableButton}
                                        onClick={() => {
                                            setOpItem(item);
                                            setOpenDialog(true)
                                        }}
                                        variant="contained">{"配置代理商"}</Button>
                                    <Button
                                        startIcon={<SettingsIcon style={{fontSize: 16}}/>}
                                        size="small"
                                        color="primary"
                                        className={classes.tableButton}
                                        onClick={() => {
                                            setOpItem(item);
                                            setOpenSourceDialog(true)
                                        }}
                                        variant="contained">{"配置订单源"}</Button>

                                </>
                            )
                        }
                    ]}
                />
            </CardContent>
            <CardActions className={classes.actions}>
                <Pagination
                    count={Math.ceil(total / rowsPerPage) || 1}
                    page={page}
                    onChange={handlePageChange}
                    shape="rounded"/>
            </CardActions>
            <OpeDialog
                maxWith={"lg"}
                fullWith={true}
                title={"选择代理商"}
                opeContent={
                    (
                        <AgentList
                            selectedAgent={opItem.agent}
                            onAgentSelected={(item) => {
                                setOpItem({
                                    ...opItem,
                                    agent: item?.agent
                                })
                            }}/>
                    )
                }
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                doDialog={() => {
                    bindAgent(opItem.userName, opItem.agent)
                }}
                okText="确认"
                okType="primary"
            />
            <OpeDialog
                maxWith={"lg"}
                fullWith={true}
                title={"选择订单源"}
                opeContent={
                    (
                        <SourceList
                            selected={opItem.source}
                            onSelected={(item) => {
                                setOpItem({
                                    ...opItem,
                                    source: item?.sourceId
                                })
                            }}/>
                    )
                }
                openDialog={openSourceDialog}
                setOpenDialog={setOpenSourceDialog}
                doDialog={() => {
                    bindSource(opItem.userName, opItem.source)
                }}
                okText="确认"
                okType="primary"
            />

        </Card>
    );
};

DataTable.propTypes = {
    className: PropTypes.string,
    data: PropTypes.array.isRequired
};

export default DataTable;
