import {Button, Divider, Grid, Typography} from "@material-ui/core";
import apis from "apis";
import React from "react";
import PropTypes from "prop-types";
import {Alert, AlertTitle} from "@material-ui/lab";

const Pay = (props) => {
    const {orderResponse, onPayResponse} = props;

    return (
        <div style={{padding: 20}}>
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <Typography variant="h4">
                        订单号
                    </Typography>
                    {orderResponse.orderNo}
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="h4">
                        订单总价
                    </Typography>
                    {orderResponse.orderPrice}
                </Grid>
            </Grid>
            <Divider style={{margin: '20px 0'}}/>
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <Button variant="contained" color='primary' onClick={() => {
                        apis.payValid({
                            orderNo: orderResponse.orderNo,
                            "source": "agentDebug",
                            "forceTrace": true
                        }).then((res) => {
                            onPayResponse(res)
                        })
                    }}>支付前校验</Button>
                </Grid>
                <Grid item xs={5}>
                    <Button variant="contained" color='primary' onClick={() => {
                        apis.payNotify({
                            orderNo: orderResponse.orderNo,
                            "source": "agentDebug",
                            "forceTrace": true
                        }).then((res) => {
                            onPayResponse(res)
                        })
                    }}>模拟支付通知</Button>
                </Grid>
            </Grid>
            <Alert
                style={{marginTop: 20}}
                severity="warning">
                <AlertTitle>警告！！！</AlertTitle>
                模拟支付通知将会真实触发订单付款消息，请注意区分是否需要真实出票测试，以避免您的资金损失（您可以在代理商配置关闭消息触发，或者在您的消息接受端区分本接口调用）
            </Alert>


        </div>
    );
}

Pay.propTypes = {
    orderResponse: PropTypes.object.isRequired,
    onPayResponse: PropTypes.func.isRequired
}

export default Pay;