import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Input,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import moment from "moment";
import apis from "apis";

const useStyles = makeStyles(theme => ({
  root: {},
  inputItem: {
    width: "100%"
  },
  pos: {
    marginBottom: theme.spacing(2)
  },
  miniMargin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  inline: {
    display: "inline-block"
  }
}));

const SearchFilter = props => {
  const classes = useStyles();
  const { className, setRefresh, searchForm, setSearchForm, ...rest } = props;

  const keywordRef = useRef(null);

  return (
    <Card {...rest}
      className={clsx(classes.pos, className)}>
      <CardHeader
        title="查询条件"
      />
      <CardContent>
        <Grid
          container
          spacing={2}
          wrap="wrap"
        >
          <Grid item xs={4}>
            <Input
              inputRef={keywordRef}
              label="订单号"
              className={classes.inputItem}
              onChange={(v) => {
                setSearchForm({
                  ...searchForm,
                  "keyword": v.target.value,
                  pageNo: 1
                });
                // setRefresh(+new Date());
              }}
              value={searchForm.keyword}
              placeholder="请输入关键词"
            />
          </Grid>
        </Grid>
        <Divider className={classes.miniMargin} />
        <Button variant="contained" color="primary" 
          onClick={() => {
            setRefresh(+new Date());
          }}
        >搜索</Button>
        <Button variant="contained" color="primary" style={{ marginLeft: 10 }}
          onClick={() => {
            setSearchForm({
              ...searchForm,
              keyword: null,
            })
            keywordRef.current.value = '';
          }}
        >重置</Button>

      </CardContent>
    </Card>
  );
};


SearchFilter.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object,
  searchForm: PropTypes.object,
  setSearchForm: PropTypes.func
};
export default SearchFilter;