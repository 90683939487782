import {Card, CardContent, CardHeader, Button} from "@material-ui/core";
import {Table} from 'views/common';
import React from "react";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    mt: {
        marginTop: theme.spacing(2)
    },
}));

const CHANGE_TYPES = {
  1: "取消",
  2: "变更",
}

const SUB_TYPES = {
  0: "计划",
 1: "起飞",
 2: "到达",
 10: "延误",
 20: "取消",
 21: "航班保护",
 25: "提前",
 26: "变更",
 27: "机场变更",
 28: "恢复",
 99: "其他"
}

const FlightChangeFragment = (props) => {
    const {order, reload} = props;
    const classes = useStyles();


    return (
        <Card className={classes.mt}>
            <CardHeader title="航变信息"/>
            <CardContent>
                <Table columns={[
                    {
                        label: '航变原因',
                        render: (item) => {
                            return item.changeReason;
                        }
                    },
                    {
                        label: '航变时间',
                        render: (item) => {
                            return item.changeTime;
                        }
                    },
                    {
                        label: "航变类型",
                        render: (item) => {
                            return CHANGE_TYPES[item.changeType];
                        }
                    },
                    {
                        label: "航变子类型",
                        render: (item) => {
                            return SUB_TYPES[item.subType];
                        }
                    }, {
                        label: "新到达机场",
                        render: (item) => {
                            return item.newArriveName;
                        }
                    }, {
                        label: "新到达时间",
                        render: (item) => {
                            return item.newArriveTime;
                        }
                    }, {
                        label: "新出发机场",
                        render: (item) => {
                            return item.newDepartName;
                        }
                    }, {
                        label: "新出发时间",
                        render: (item) => {
                            return item.newDepartTime;
                        }
                    }, {
                      label: "新航班号",
                      render: (item) => {
                          return item.newFlightNo;
                      }
                    }, {
                      label: "到达机场",
                      render: (item) => {
                          return item.oldArriveName;
                      }
                    }, {
                      label: "到达时间",
                      render: (item) => {
                          return item.oldArriveTime;
                      }
                    }, {
                      label: "出发机场",
                      render: (item) => {
                          return item.oldDepartName;
                      }
                    }, {
                      label: "出发时间",
                      render: (item) => {
                          return item.oldDepartTime;
                      }
                    }, {
                      label: "航班号",
                      render: (item) => {
                          return item.oldFlightNo;
                      }
                    }

                ]}
                       data={order.flightChangeList}
                >
                </Table>
            </CardContent>
        </Card>
    );
}

export default FlightChangeFragment;