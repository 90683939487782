import {Card, CardContent, CardHeader} from "@material-ui/core";
import {Table} from 'views/common';
import React from "react";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    mt: {
        marginTop: theme.spacing(2)
    },
}));

const OrderPolicyFragment = (props) => {
    const {order} = props;
    const classes = useStyles();

    return (
        <Card className={classes.mt}>
            <CardHeader title="调价政策"/>
            <CardContent>
            <Table columns={[
                        {
                            label: '政策ID',
                            key: 'policyId'
                        },
                        {
                            label: "行程(出发/到达)",
                            render: (item) => {
                                return (
                                    <p>
                                        {item.dep}/{item.arr}
                                    </p>
                                )
                            }
                        },
                        {
                            label: "航班(航司/航班/仓位)",
                            render: (item) => {
                                return (
                                    <p>
                                        {item.carrier}/{item.flightNo}/{item.cabin}
                                    </p>
                                )
                            }
                        },
                        {
                            label: "起飞天数",
                            key: 'afterDay'
                        },
                        {
                            label: "生效时间",
                            render: (item) => {
                                return (
                                    <p>
                                        {item.fromDate}&nbsp;&nbsp;|&nbsp;&nbsp;{item.toDate}
                                    </p>
                                )
                            }
                        },
                        {
                            label: "手续费调整百分比（退票 | 改期）",
                            render: (item) => {
                                return (
                                    <p>
                                        {item.refundEditPercent}%&nbsp;&nbsp;|&nbsp;&nbsp;{item.changeEditPercent}%
                                    </p>
                                )
                            }
                        },
                        {
                            label: "改价规则",
                            render: (item) => {
                                return (
                                    <div>
                                        <div>{item.rangeSpace}</div>
                                        <div>{item.fd}</div>
                                        <div>{item.lq}</div>
                                    </div>
                                )
                            }
                        },
                        {
                            label: "政策优先级",
                            key: 'priority'
                        }
                    ]}
                           data={order.policyList}>
                    </Table>
            </CardContent>
        </Card>
    );
}

export default OrderPolicyFragment;