import {makeStyles} from "@material-ui/styles";
import {Card, CardContent, CardHeader, Collapse, IconButton} from "@material-ui/core";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Table} from "views/common";
import React from "react";


const useStyles = makeStyles(theme => ({
    mt: {
        marginTop: theme.spacing(2)
    },
}));


const PassengerFragment = (props) => {
    const {order} = props;
    const classes = useStyles();
    const [passengerExpanded, setPassengerExpanded] = React.useState(true);

    return (<Card className={classes.mt}>
        <CardHeader title="乘客列表"
                    action={(
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: passengerExpanded,
                            })}
                            onClick={() => setPassengerExpanded(!passengerExpanded)}
                            aria-expanded={passengerExpanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon/>
                        </IconButton>
                    )}
        />
        <Collapse in={passengerExpanded} timeout="auto" unmountOnExit>
            <CardContent>
                <Table columns={[
                    {
                        label: '乘客姓名',
                        key: 'name'
                    },
                    {
                        label: '性别',
                        render: (row, index) => {
                          return row.gender === 'MALE' ? '男' : '女';
                        }
                    },
                    {
                        label: '证件类型',
                        key: 'cardTypeName'
                    },
                    {
                        label: '证件号',
                        key: 'cardNo'
                    },
                    {
                        label: '证件过期时间',
                        key: 'cardExpire'
                    },
                    {
                        label: '证件签发国（地区）',
                        key: 'cardIssuePlace'
                    },
                    {
                        label: '手机号',
                        key: 'phone'
                    }
                ]}
                       data={order.passengerList}>
                </Table>
            </CardContent>
        </Collapse>
    </Card>);
}

export default PassengerFragment;