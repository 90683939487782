import React, {useState, useContext} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Pagination from '@material-ui/lab/Pagination';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {Button, Grid, TextField, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {Table} from 'views/common';
import {useSnackbar} from 'notistack';
import {
    Card,
    CardActions,
    CardContent,
} from '@material-ui/core';
import {AppContext} from "adapter";
import apis from 'apis';
import SettingsIcon from "@material-ui/icons/Settings";
import {OpeDialog} from "components";

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 0
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(2)
    },
    actions: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        justifyContent: 'center'
    },
    tableButton: {
        marginRight: theme.spacing(1)
    },
}));

const DataTable = props => {
    const {enqueueSnackbar} = useSnackbar();
    const {className, data, total, rowsPerPage, pageState, setRefresh, ...rest} = props;
    const [page, setPage] = pageState;
    const {user} = useContext(AppContext);

    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);

    const [editItem, setEditItem] = useState({});


    const handlePageChange = (event, page) => {
        setPage(page);
    };

    const doDelete = (item) => {
        apis
            .codeMapDelete({id: item.id})
            .then(res => {
                if (res.status === 0) {
                    enqueueSnackbar('删除成功', {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                    });
                    setRefresh(+new Date());
                } else {
                    enqueueSnackbar(res.error, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                    });
                }
            }).catch((e) => {
            enqueueSnackbar(e.message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            });
        });
    }

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardContent className={classes.content}>
                <Table
                    data={data}
                    columns={[
                        {
                            label: '机场code',
                            key: 'airportCode'
                        },
                        {
                            label: '城市code',
                            key: 'cityCode'
                        },
                        {
                            label: '机场名称',
                            key: 'airportName'
                        },
                        {
                            label: '机场简写',
                            key: 'airportNameShorten'
                        },
                        {
                            label: '城市名称',
                            key: 'cityName'
                        },
                        {
                            label: '城市行政区划码',
                            key: 'xzqhCode'
                        },

                        {
                            label: '操作',
                            render: (item) => (
                                user.isAdmin ? <>
                                    <Button
                                        startIcon={<DeleteForeverIcon style={{fontSize: 16}}/>}
                                        size="small"
                                        color="secondary"
                                        className={classes.tableButton}
                                        onClick={() => doDelete(item)}
                                        variant="contained">删除</Button>
                                    <Button
                                        startIcon={<SettingsIcon style={{fontSize: 16}}/>}
                                        size="small"
                                        color="primary"
                                        className={classes.tableButton}
                                        onClick={() => {
                                            setEditItem(item);
                                            setOpenDialog(true)
                                        }}
                                        variant="contained">修改</Button>
                                </> : <></>

                            )
                        }
                    ]}
                />
            </CardContent>
            <CardActions className={classes.actions}>
                <Pagination
                    count={Math.ceil(total / rowsPerPage) || 1}
                    page={page}
                    onChange={handlePageChange}
                    shape="rounded"/>
            </CardActions>
            <OpeDialog
                opeContent={(
                    <>
                        <Grid
                            container
                            spacing={6}
                            wrap="wrap"
                        >
                            <Grid item xs={12}>
                                <Typography gutterBottom variant="h6">机场code</Typography>
                                <TextField
                                    className={classes.dialogInput}
                                    size="small"
                                    variant="outlined"
                                    value={editItem.airportCode}
                                    onChange={(e) => setEditItem({
                                        ...editItem,
                                        airportCode: e.target.value
                                    })}/>
                                <Typography gutterBottom variant="h6">城市code</Typography>
                                <TextField
                                    className={classes.dialogInput}
                                    size="small"
                                    variant="outlined"
                                    value={editItem.cityCode}
                                    onChange={(e) => setEditItem({
                                        ...editItem,
                                        cityCode: e.target.value
                                    })}/>
                                <Typography gutterBottom variant="h6">机场名称</Typography>
                                <TextField
                                    className={classes.dialogInput}
                                    size="small"
                                    variant="outlined"
                                    value={editItem.airportName}
                                    onChange={(e) => setEditItem({
                                        ...editItem,
                                        airportName: e.target.value
                                    })}/>
                                <Typography gutterBottom variant="h6">机场名简写</Typography>
                                <TextField
                                    className={classes.dialogInput}
                                    size="small"
                                    variant="outlined"
                                    value={editItem.airportNameShorten}
                                    onChange={(e) => setEditItem({
                                        ...editItem,
                                        airportNameShorten: e.target.value
                                    })}/>
                                <Typography gutterBottom variant="h6">城市名称</Typography>
                                <TextField
                                    className={classes.dialogInput}
                                    size="small"
                                    variant="outlined"
                                    value={editItem.cityName}
                                    onChange={(e) => setEditItem({
                                        ...editItem,
                                        cityName: e.target.value
                                    })}/>
                                <Typography gutterBottom variant="h6">城市行政区划码</Typography>
                                <TextField
                                    className={classes.dialogInput}
                                    size="small"
                                    variant="outlined"
                                    value={editItem.xzqhCode}
                                    onChange={(e) => setEditItem({
                                        ...editItem,
                                        xzqhCode: e.target.value
                                    })}/>
                                <Typography gutterBottom variant="h6">出发提醒</Typography>
                                <TextField
                                    multiline={true}
                                    minRows={3}
                                    className={classes.dialogInput}
                                    size="small"
                                    variant="outlined"
                                    value={editItem.depNotice}
                                    onChange={(e) => setEditItem({
                                        ...editItem,
                                        depNotice: e.target.value
                                    })}/>
                                <Typography gutterBottom variant="h6">到达提醒</Typography>
                                <TextField
                                    multiline={true}
                                    minRows={3}
                                    className={classes.dialogInput}
                                    size="small"
                                    variant="outlined"
                                    value={editItem.arrNotice}
                                    onChange={(e) => setEditItem({
                                        ...editItem,
                                        arrNotice: e.target.value
                                    })}/>
                            </Grid>
                        </Grid>
                    </>
                )}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                doDialog={() => {
                    return apis.codeMapUpdate(editItem).then(res => {
                        if (res.status === 0) {
                            setRefresh(+new Date());
                            return '操作成功';
                        }
                        throw new Error(res.message);
                    });
                }}
                okText="保存"
                okType="primary"/>
        </Card>
    );
};

DataTable.propTypes = {
    className: PropTypes.string,
    data: PropTypes.array.isRequired
};

export default DataTable;
