import { useEffect, useState } from "react";
import {Card, CardContent, CardHeader, Button, Dialog, DialogTitle, DialogActions, DialogContent, FormControl, FormControlLabel, FormLabel, FormGroup,
        Checkbox} from "@material-ui/core";
import {Table} from 'views/common';
import React from "react";
import {makeStyles} from "@material-ui/styles";
import apis from 'apis';
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
    mt: {
        marginTop: theme.spacing(2)
    },
}));

const InsuranceFragment = (props) => {
    const {order, reload} = props;
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [openRefundDialog, setOpenRefundDialog] = useState(false);
    const [selectedInsuranceId, setSelectedInsuranceId] = useState(null);
    const [refundConfirmBtnDisabled, setRefundConfirmBtnDisabled] = useState(true);

    return (
        <Card className={classes.mt}>
            <CardHeader title="保险信息"/>
            <CardContent>
                <Table columns={[
                    {
                      label: 'id',
                      render: (item) => {
                          return item.id;
                      }
                    },{
                        label: 'outOrderId',
                        render: (item) => {
                            return item.outOrderId;
                        }
                    },
                    {
                        label: 'tcOrderId',
                        render: (item) => {
                            return item.tcOrderId;
                        }
                    },
                    {
                        label: "创建时间",
                        render: (item) => {
                            return item.gmtCreated;
                        }
                    }, {
                        label: "修改时间",
                        render: (item) => {
                            return item.gmtModified;
                        }
                    }, {
                        label: "premiumId",
                        render: (item) => {
                            return item.product?.insurancePremiumId;
                        }
                    }, {
                        label: "名称",
                        render: (item) => {
                            return item.product?.productName;
                        }
                    }, {
                        label: "价格",
                        render: (item) => {
                            return item.product?.price;
                        }
                    }, {
                      label: "详情页",
                      render: (item) => {
                          return (<>
                            <a target="_blank" href={item.detail?.policyDetailUrl}>保单</a>
                          </>);
                      }
                    }, {
                      label: '操作',
                      render: (item) => {
                        if (item.status === 'OK') {
                          return (
                          <>
                              <Button
                                  size="small"
                                  color="primary"
                                  onClick={() => {
                                    setOpenRefundDialog(true); 
                                    setSelectedInsuranceId(item.id);
                                  }}
                                  variant="contained">退保</Button>
                          </>)
                        } else {
                          return <></>
                        }
                        
                      }
                    }

                ]}
                       data={order.insuranceList || []}
                >
                </Table>
            </CardContent>
            <Dialog
              open={openRefundDialog}
              onClose={() => setOpenRefundDialog(false)}
              maxWidth="md"
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"退保"}</DialogTitle>
              <DialogContent>
                
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend">乘机人</FormLabel>
                  <FormGroup>
                    {
                    order.passengerList.map(p => {
                      return (
                        <FormControlLabel
                          control={<Checkbox checked={p.selected} onChange={(_, checked) => {
                            p.selected = checked;
                            setRefundConfirmBtnDisabled(order.passengerList.filter(p => p.selected).length === 0);
                          }} name={p.cardNo} />}
                          label={p.name}
                        />
                      )
                    })
                    }
                  </FormGroup>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenRefundDialog(false)} color="primary">
                  取消
                </Button>
                <Button onClick={
                  () => {
                    setOpenRefundDialog(false)
                    apis.refundInsurance({
                      orderNo: order.orderInfo.orderNo, 
                      insuranceId: selectedInsuranceId,
                      cardNoes: order.passengerList.filter(p => p.selected).map(p => p.cardNo).join(',')
                    }).then(res => {
                      if (res.status === 0) {
                        enqueueSnackbar('操作成功', {
                          variant: 'success',
                          anchorOrigin: {
                              vertical: 'top',
                              horizontal: 'center',
                          },
                        });
                        return;
                      }
                      enqueueSnackbar(res.message, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                    });
                    }).catch((e) => {
                      enqueueSnackbar(e.msg, {
                          variant: 'error',
                          anchorOrigin: {
                              vertical: 'top',
                              horizontal: 'center',
                          },
                      })
                    });
                  }
                } color="primary" disabled={refundConfirmBtnDisabled}>
                  确定
                </Button>
              </DialogActions>
            </Dialog>
        </Card>
    );
}

export default InsuranceFragment;