import {Card, CardContent, CardHeader} from "@material-ui/core";
import {Table} from 'views/common';
import React from "react";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    mt: {
        marginTop: theme.spacing(2)
    },
}));

const TripFragment = (props) => {
    const {order} = props;
    const classes = useStyles();

    return (
        <Card className={classes.mt}>
            <CardHeader title="行程信息"/>
            <CardContent>
                <Table columns={[
                    {
                        label: '航班号',
                        key: 'flightNo'
                    },
                    {
                        label: '出发',
                        key: 'depName'
                    },
                    {
                        label: "到达",
                        key: 'arrName'
                    }, {
                        label: "仓位",
                        key: 'cabin'
                    },
                    {
                        label: '起飞时间',
                        render: (item) => {
                            return item.depDate + " " + item.dptTime;
                        }
                    }
                ]}
                       data={order.flightList}
                       collapse={true}
                       renderCollapse={(item) => (
                           <>
                               <Table data={
                                   [{
                                       ...item.inboundAdultProductInfo,
                                       "key": "代理商成人"
                                   }, {
                                       ...item.inboundChildProductInfo,
                                       "key": "代理商儿童"
                                   }, {
                                       ...item.inboundInfantProductInfo,
                                       "key": "代理商婴儿"
                                   }, {
                                       ...item.outboundAdultProductInfo,
                                       "key": "销售端成人"
                                   }, {
                                       ...item.outboundChildProductInfo,
                                       "key": "销售端儿童"
                                   }, {
                                       ...item.outboundInfantProductInfo,
                                       "key": "销售端婴儿"
                                   }
                                   ].filter(i => {
                                       return !!i.priceInfo
                                   })
                               } columns={
                                   [
                                       {
                                           label: '报价类型',
                                           key: 'key'
                                       },
                                       {
                                           label: '价格(裸票/票面/销售)',
                                           render: (item) => {
                                               return item.priceInfo?.basePrice
                                                   + "/"
                                                   + item.priceInfo?.printPrice
                                                   + "/"
                                                   + item.priceInfo?.salePrice;
                                           }
                                       },
                                       {
                                           label: '机建/燃油',
                                           render: (item) => {
                                               return item.priceInfo?.airportFee
                                                   + "/"
                                                   + item.priceInfo?.fuelTax;
                                           }
                                       },
                                       {
                                           label: '退改规则',
                                           render: (item) => {
                                               return (
                                                   <ul>
                                                       <li>退票: {item.tgqRuleVo?.refundText}</li>
                                                       <li>改期: {item.tgqRuleVo?.changeText}</li>
                                                   </ul>
                                               );
                                           }
                                       },
                                       {
                                           label: '签转',
                                           render: (item) => {
                                               return item.tgqRuleVo?.allowChange ? "允许" : "不允许"
                                           }
                                       },
                                       {
                                           label: '退改签基准价',
                                           render: (item) => {
                                               return item.tgqRuleVo?.basePrice
                                           }
                                       },
                                       {
                                           label: '退改签基准仓位',
                                           render: (item) => {
                                               return item.tgqRuleVo?.tgqCabin
                                           }
                                       },
                                       {
                                           label: '行李额',
                                           render: (item) => {
                                               return (
                                                   <ul>
                                                       <li>托运: {item.luggageInfoVo?.luggage}</li>
                                                       <li>手提: {item.luggageInfoVo?.carryOn}</li>
                                                   </ul>
                                               );
                                           }
                                       }
                                   ]
                               }/>
                           </>
                       )}
                >
                </Table>
            </CardContent>
        </Card>
    );
}

export default TripFragment;