import { Table } from "views/common";
import { Button } from "@material-ui/core";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import apis from "apis";
import React from "react";
import PropTypes from "prop-types";

const OTAList = (props) => {
  const { productList, searchParam, onOTAResponse } = props;
  return (
    <Table
      data={productList}
      columns={[
        {
          label: '出发',
          key: 'dep'
        }, {
          label: '到达',
          key: 'arr'
        },
        {
          label: '航线',
          key: 'tripId'
        }, {
          label: '出发时间',
          key: 'depTime'
        }, {
          label: '仓位/价格',
          key: 'cabin'
        }, {
          label: '操作',
          render: (item) => (
            <>
              <Button
                startIcon={<MotorcycleIcon style={{ fontSize: 16 }} />}
                size="small"
                color="primary"
                onClick={() => {
                  apis.flightSearch({
                    ...searchParam,
                    dep: item.dep,
                    arr: item.arr,
                    flightNo: item.tripId,
                    depTime: item.depTime,
                    "source":"agentDebug",
                    "forceTrace": true
                  }).then((res) => {
                    onOTAResponse(res);
                  })
                }}
                variant="contained">OTA搜索</Button>
            </>
          )
        }
      ]}
    />
  )
}

OTAList.propTypes = {
  productList: PropTypes.array.isRequired,
  searchParam: PropTypes.object.isRequired,
  onOTAResponse: PropTypes.func.isRequired,
}


export default OTAList;