import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';

import {Table} from './components';
import SearchFilter from './SearchFilter'
import apis from 'apis';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    }
}));

const OrderList = () => {
    const classes = useStyles();

    const [showData, setShowData] = useState([]);
    const [total, setTotal] = useState(0);

    let initTaskListParam = JSON.parse(localStorage.getItem("orderListParam") || "{}");
    const [searchParams, setSearchParams] = useState({
        fromDate: null,
        toDate: null,
        depDate: null,
        orderNo: null,
        phone: null,
        passengerName: null,
        carrier: 'ALL',
        orderStatus: 'ALL',
        orderSource: null,
        pageNo: 1,
        ...initTaskListParam
    });
    useEffect(() => {
        localStorage.setItem("orderListParam", JSON.stringify(searchParams));
    }, [searchParams])


    const [refresh, setRefresh] = useState(+new Date());

    useEffect(() => {
        const getData = () => {
            let param = {...searchParams};
            if ("ALL" === param.orderStatus) {
                delete param.orderStatus;
            }
            if ("ALL" === param.carrier) {
                delete param.carrier;
            }

            apis.orderSearch(param)
                .then(res => {
                    if (res.status === 0) {
                        setShowData(res.data.records);
                        setTotal(res.data.total);
                    }
                }).catch(e => console.log(e));
        }
        getData();
    }, [refresh]);


    return (
        <div className={classes.root}>
            <SearchFilter
                searchForm={searchParams}
                setSearchForm={setSearchParams}
                setRefresh={setRefresh}
            />

            <div className={classes.content}>
                <Table
                    data={showData}
                    total={total}
                    pageState={[searchParams.pageNo, (page) => {
                        setSearchParams({...searchParams, pageNo: page})
                    }]}
                    setRefresh={setRefresh}/>
            </div>
        </div>
    );
};

export default OrderList;
