import {Button, Card, CardActions, CardHeader, Grid, MenuItem, Select, TextField, Typography} from "@material-ui/core";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import apis from "apis";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

import {useSnackbar} from "notistack";
import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";
import Table from "views/common/Table";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import moment from "moment/moment";
import RandomPassenger from "./RandomPassenger";
import IDCard from './IDCard'

const useStyles = makeStyles(() => ({
    root: {},
    inputItem: {
        width: "100%",
        marginTop: 20
    }, panel: {
        marginBottom: 8,
        padding: 4
    }
}));

// todo 枚举走后台配置
const cardTypeMap = [
    {
        "key": "NI",
        "name": "身份证"
    },
    {
        "key": "PP",
        "name": "护照"
    }, {
        "key": "HR",
        "name": "户口簿"
    }, {
        "key": "HX",
        "name": "回乡证"
    }, {
        "key": "TB",
        "name": "台胞证"
    }, {
        "key": "HM",
        "name": "港澳台居民居住证"
    }, {
        "key": "JR",
        "name": "军人证"
    }, {
        "key": "BC",
        "name": "出生证明"
    }, {
        "key": "OT",
        "name": "其他"
    }
]

const initPsg = {
    "name": "",
    "cardType": "NI",
    "cardNo": "",
    "birthday": "",
    "cardExpire": "",
    "phone": "",
    "ageType": 0
};

const add = (num1, num2) => num1 + num2;

const prefixArray = ["130", "131", "132", "133", "135", "137", "138", "170", "187", "189"];

function randomMobileNum() {
    const i = Math.floor(prefixArray.length * Math.random());
    let prefix = prefixArray[i];
    for (let j = 0; j < 8; j++) {
        prefix = prefix + Math.floor(Math.random() * 10);
    }
    return prefix;
}

const Order = (props) => {
    const classes = useStyles();
    const {className, searchParam, bookingResult, onOrderResponse} = props;

    const {enqueueSnackbar} = useSnackbar();

    const [totalPrice, setTotalPrice] = useState(0);
    const [passengerList, setPassengerList] = useState([]);
    const [contact, setContact] = useState({
        fullName: "",
        phoneNumber: randomMobileNum()
    });
    const [editPassenger, setEditPassenger] = useState(initPsg);

    let allTrip = [...(bookingResult['product']?.go || []), ...(bookingResult['product']?.back || [])];
    const adultPrice = allTrip.length === 0 ? 0 : allTrip.map((item) => {
        return item.adultPrice?.salePrice || -1;
    }).reduce(add);

    const childPrice = allTrip.length === 0 ? 0 : allTrip.map((item) => {
        return item.childPrice?.salePrice || -1;
    }).reduce(add);

    const infantPrice = allTrip.length === 0 ? 0 : allTrip.map((item) => {
        return item.infantPrice?.salePrice || -1;
    }).reduce(add);

    useEffect(() => {
        const price = passengerList.length === 0 ? 0 : passengerList.map((item) => {
            switch (item.ageType) {
                case 0:
                    return adultPrice;
                case 1:
                    return childPrice;
                case 2:
                    return infantPrice;
                default:
                    return -1;
            }
        }).reduce(add);
        setTotalPrice(price)
    }, [passengerList])

    const randomUser = (randomList) => {
        let index = Math.floor(Math.random() * randomList.length);
        let psg = randomList[index];
        randomList.splice(index, 1)
        setEditPassenger(psg);
    }

    const appendPsg = () => {
        if (!editPassenger.name || !editPassenger.cardType || !editPassenger.cardNo) {
            enqueueSnackbar("请完善乘机人信息", {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            });
            return
        }

        // 填充默认联系人
        if (!contact.fullName) {
            setContact({
                ...contact,
                fullName: editPassenger.name
            });
        }
        if (!contact.phoneNumber && !!editPassenger.phone) {
            setContact({
                ...contact,
                phoneNumber: editPassenger.phone
            });
        }

        let copyEditPsg = {...editPassenger}

        if (copyEditPsg.cardType === 'NI') {
            let idCard = new IDCard(editPassenger.cardNo);
            if (!idCard.IsValid()) {
                enqueueSnackbar("身份证格式不正确", {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                });
                return;
            }
            // 如果是身份证，那么使用身份证的出生日期作为生日字段
            copyEditPsg.birthday = idCard.GetBirthDate()
        }

        if (!copyEditPsg.birthday) {
            enqueueSnackbar("请填写乘客生日", {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            });
            return;
        }


        // 根据生日判断成人、儿童、婴儿类型
        let depData = searchParam?.depDate ? moment(searchParam.depDate) : moment(new Date()).add(3, "day");
        let age = Math.abs(moment(copyEditPsg.birthday).diff(depData, 'years'));
        copyEditPsg.ageType = age > 12 ? 0 : (age > 2 ? 1 : 2);
        if (copyEditPsg.ageType === 2 && infantPrice < 0) {
            enqueueSnackbar("本报价不支持婴儿", {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            });
            return;
        }
        if (copyEditPsg.ageType === 1 && childPrice < 0) {
            enqueueSnackbar("本报价不支持婴儿", {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            });
            return;
        }

        setPassengerList([...passengerList, copyEditPsg]);
        setEditPassenger({
            ...initPsg
        })
    }

    const submitOrder = () => {
        if (passengerList.length === 0) {
            enqueueSnackbar("请添加乘机人", {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            });
            return;
        }
        if (!contact.fullName || !contact.phoneNumber) {
            enqueueSnackbar("请填写联系人", {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            });
            return;
        }
        apis.flightOrder({
            "bookingKey": bookingResult.bookingKey,
            "orderPrice": totalPrice,
            "contact": contact,
            "passengerList": passengerList,
            "source": "agentDebug",
            "forceTrace": true
        }).then((res) => {
            onOrderResponse(res);
        })
    }

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Typography variant="h4">
                        航线
                    </Typography>
                    <Typography variant="h6">
                        {bookingResult['product']?.tripId}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="h4">
                        仓位详情
                    </Typography>
                    <Typography variant="h4">
                        {({...bookingResult['product']}.go || []).map(route => {
                            return route.cabin + "/" + route.adultPrice.basePrice
                        }).join(",") + ((bookingResult['product']?.back || []).length === 0 ? '' : "->" + ({...bookingResult['product']}.back || []).map(route => {
                            return route.cabin + "/" + route.adultPrice.basePrice
                        }).join(","))}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="h4">
                        订单总价:
                    </Typography>
                    <Typography variant="h4">
                        {totalPrice}
                    </Typography>
                </Grid>
            </Grid>

            <Card className={clsx(classes.panel, className)}>
                <CardHeader title="联系人"></CardHeader>
                <Grid container spacing={2}>
                    <Grid item xs={5}>

                        <TextField
                            label="姓名"
                            variant="outlined"
                            color="secondary"
                            value={contact.fullName}
                            onChange={e => {
                                setContact({
                                        ...contact,
                                        fullName: e.target.value
                                    }
                                )
                            }}
                            className={classes.inputItem}
                        />

                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            label="电话"
                            variant="outlined"
                            color="secondary"
                            value={contact.phoneNumber}
                            onChange={e => {
                                setContact({
                                        ...contact,
                                        phoneNumber: e.target.value
                                    }
                                )
                            }}
                            className={classes.inputItem}
                        />
                    </Grid>
                </Grid>
            </Card>

            <Card className={clsx(classes.panel, className)}>
                <CardHeader title="乘机人列表"></CardHeader>
                <Table size={"small"} data={passengerList} columns={
                    [
                        {
                            label: '姓名',
                            key: 'name'
                        },
                        {
                            label: '证件类型',
                            key: 'cardType'
                        },
                        {
                            label: '证件号',
                            key: 'cardNo'
                        },
                        {
                            label: '生日',
                            key: 'birthday'
                        },
                        {
                            label: '乘机人类型',
                            render: (item) => ({
                                0: "成人",
                                1: "儿童",
                                2: "婴儿",
                            }[item['ageType']])
                        },
                        {
                            label: '操作',
                            render: (item) => (
                                <Button
                                    startIcon={<DeleteForeverIcon style={{fontSize: 16}}/>}
                                    size="small"
                                    color="primary"
                                    className={classes.tableButton}
                                    onClick={() => {
                                        let tmpPsgList = [...passengerList]
                                        tmpPsgList.splice(tmpPsgList.indexOf(item), 1)
                                        setPassengerList(tmpPsgList)
                                    }}
                                    variant="contained">删除</Button>
                            )
                        }
                    ]
                }/>
            </Card>
            <Card className={clsx(classes.panel, className)}
            >
                <CardHeader title="乘机人录入" action={
                    <CardActions className={classes.actions}>
                        <Button
                            size="small"
                            color="primary"
                            onClick={() => {
                                randomUser(RandomPassenger.randomAdult)
                            }}
                            variant="contained">随机成人</Button>
                        <Button
                            size="small"
                            color="primary"
                            onClick={() => {
                                randomUser(RandomPassenger.randomChild)
                            }}
                            variant="contained">随机儿童</Button>
                        <Button
                            size="small"
                            color="primary"
                            onClick={() => {
                                randomUser(RandomPassenger.randomPP)
                            }}
                            variant="contained">随机护照</Button>
                        <Button
                            size="small"
                            color="primary"
                            onClick={appendPsg}
                            variant="contained">确认</Button>
                    </CardActions>
                }></CardHeader>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField
                            label="姓名"
                            variant="outlined"
                            color="secondary"
                            value={editPassenger.name}
                            onChange={e => {
                                setEditPassenger({
                                        ...editPassenger,
                                        name: e.target.value
                                    }
                                )
                            }}
                            className={classes.inputItem}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="证件号"
                            variant="outlined"
                            color="secondary"
                            value={editPassenger.cardNo}
                            onChange={e => {
                                setEditPassenger({
                                        ...editPassenger,
                                        cardNo: e.target.value
                                    }
                                )
                            }}
                            className={classes.inputItem}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            label={<Typography style={{
                                textAlign: "center"
                            }}>
                                证件类型:
                            </Typography>}
                            className={classes.inputItem}
                            variant="outlined"
                            value={editPassenger.cardType}
                            onChange={(e) => {
                                setEditPassenger({
                                    ...editPassenger,
                                    "cardType": e.target.value
                                });
                            }}
                        >
                            {cardTypeMap.map(k => (
                                <MenuItem key={k.key} value={k.key}>
                                    <pre>{k.name}</pre>
                                </MenuItem>
                            ))}

                        </Select>

                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField
                            label="手机号"
                            variant="outlined"
                            color="secondary"
                            value={editPassenger.phone}
                            onChange={e => {
                                setEditPassenger({
                                        ...editPassenger,
                                        phone: e.target.value
                                    }
                                )
                            }}
                            className={classes.inputItem}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="生日"
                            variant="outlined"
                            color="secondary"
                            type="date"
                            value={editPassenger.birthday}
                            onChange={e => {
                                setEditPassenger({
                                        ...editPassenger,
                                        birthday: moment(e.target.value).format("YYYY-MM-DD")
                                    }
                                )
                            }}
                            className={classes.inputItem}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="证件过期时间"
                            variant="outlined"
                            color="secondary"
                            type="date"
                            value={editPassenger.cardExpire}
                            onChange={e => {
                                setEditPassenger({
                                        ...editPassenger,
                                        cardExpire: moment(e.target.value).format("YYYY-MM-DD")
                                    }
                                )
                            }}
                            className={classes.inputItem}
                        />
                    </Grid>
                </Grid>
            </Card>
            <Button
                startIcon={<MotorcycleIcon style={{fontSize: 16}}/>}
                size="small"
                color="primary"
                onClick={submitOrder}
                variant="contained">Order</Button>
        </div>)
}

Order.propTypes = {
    bookingResult: PropTypes.object.isRequired,
    searchParam: PropTypes.object.isRequired,
    onOrderResponse: PropTypes.func.isRequired,
}

export default Order;