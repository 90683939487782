import React, {useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {OpeDialog, SearchInput} from 'components';
import {Button, TextField, Typography} from '@material-ui/core';

import clsx from 'clsx';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import apis from "apis";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    searchInput: {},
    dialog: {
        width: theme.spacing(60)
    },
    dialogInput: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
}));

const Toolbar = props => {
    const {enqueueSnackbar} = useSnackbar();
    const {className, onInputChange, setRefresh, isSelector, ...rest} = props;
    const classes = useStyles();
    const [editItem, setEditItem] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <div className={classes.row}>
                <SearchInput
                    className={classes.searchInput}
                    onChange={(v) => onInputChange(v)}
                    placeholder="请输入关键词进行查询"
                />
                {!isSelector ? (
                    <>
                        <span className={classes.spacer}/>
                        <Button
                            startIcon={<QueuePlayNextIcon/>}
                            color="primary"
                            variant="contained"
                            onClick={() => setOpenDialog(true)}
                        >
                            创建代理商
                        </Button></>
                ) : null}

            </div>
            <OpeDialog
                title="创建代理商"
                opeContent={(
                    <>
                        <Typography gutterBottom variant="h6">代理商代码</Typography>
                        <TextField
                            className={classes.inputItem}
                            size="small"
                            variant="outlined"
                            placeholder="代理商代码"
                            value={editItem.agent}
                            onChange={(e) => setEditItem({
                                ...editItem,
                                agent: e.target.value
                            })}/>
                        <Typography gutterBottom variant="h6">代理商描述</Typography>
                        <TextField
                            className={classes.dialogInput}
                            size="small"
                            variant="outlined"
                            value={editItem.agentDesc}
                            onChange={(e) => setEditItem({
                                ...editItem,
                                agentDesc: e.target.value
                            })}/>

                    </>
                )}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                doDialog={() => {
                    return apis.createAgent(editItem).then(res => {
                        if (res.status === 0) {
                            setEditItem({})
                            setRefresh(+new Date());
                            return '操作成功';
                        } else {
                            enqueueSnackbar(res.error, {
                                variant: 'error',
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                },
                            });
                        }
                    }).catch((e) => {
                        enqueueSnackbar(e.message, {
                            variant: 'error',
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            },
                        })
                    });
                }}
                okText="保存"
                okType="primary"/>
        </div>
    );
};

export default Toolbar;
