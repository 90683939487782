import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from "react-router-dom";
import { Button, Card, CardActions, CardContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Table } from 'views/common';
import { AppContext } from "adapter";
import VisibilityIcon from "@material-ui/icons/Visibility";

export const STATUS = {
  INIT: '初始化',
  PRE_PAY: '待支付',
  AGENT_ORDER_OK: '下单成功', // 代理商下单成功
  AGENT_ORDER_FAILED: '下单失败', // 代理商下单失败
  TIMEOUT_CANCEL: '超时取消', // 超时未支付取消
  USER_PAY: '出票中', // 用户已支付，出票中
  USER_PAY_AGENT_NOTIFY_FAILED: '出票中', // 用户已支付，代理商通知失败
  USER_CANCEL: '用户取消', // 用户支付后取消拦截
  ISSUE_TICKET_OK: '出票成功',
  ISSUE_TICKET_PART_OK: '部分出票', // 部分出票(多航段)
  ISSUE_TICKET_FAILED: '出票失败',
  REFUND_OK: '退票成功',
  REFUND_ERROR: '退票失败',
  REFUND_WAIT: '部分退票',
  REFUND_APPLY: '退票申请中',
  HAS_REFUND: '有退票',
  HAS_CHANGE: '有改签',
  HAS_REFUND_CHANGE: '有退改'
}

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    justifyContent: 'center'
  },
  tableButton: {
    marginRight: theme.spacing(1)
  },
}));

const DataTable = props => {
  const { className, data, total, pageState, setRefresh, ...rest } = props;
  const [page, setPage] = pageState;
  const { user } = useContext(AppContext);

  const classes = useStyles();
  const history = useHistory();

  const handlePageChange = (event, page) => {
    setPage(page);
    setRefresh(+new Date());
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <Table
          collapse={true}
          renderCollapse={(item) => (
            <>
              <Grid container spacing={12} wrap="wrap">
                <Grid item xs={4}>
                  上游订单号：{item.sourceOrderNo}
                </Grid>
                <Grid item xs={4}>
                  代理商订单号：{JSON.parse(item.agentExtInfo).fliggyOrderId ? JSON.parse(item.agentExtInfo).fliggyOrderId : item.agentOrderNo}
                </Grid>
                <Grid item xs={4}>
                  代理商ID：{item.agent}
                </Grid>
                <Grid item xs={4}>
                  订单源：{item.source}
                </Grid>
                <Grid xs={4}>
                  返程日期：{item.retDate}
                </Grid>
                <Grid item xs={4}>
                  订单状态：{STATUS[item.orderStatus]}
                </Grid>
                <Grid item xs={4}>
                  联系人：{item.contactName}
                </Grid>
              </Grid>
            </>
          )}
          data={data}
          columns={[
            {
              label: '主订单号',
              key: 'orderNo'
            },
            {
              label: '出发',
              key: 'depName'
            },
            {
              label: '到达',
              key: 'arrName'
            },
            {
              label: '出发日期',
              key: 'depDate',
            },
            {
              label: '总价',
              key: 'orderPrice',
            },
            {
              label: '订单日期',
              key: 'createTime'
            },
            {
              label: '操作',
              render: (item) => (
                user.isAdmin ? <>
                  <Button
                    startIcon={<VisibilityIcon style={{ fontSize: 16 }} />}
                    size="small"
                    color="primary"
                    className={classes.tableButton}
                    onClick={() => {
                      history.push("/orderDetail/" + item.orderNo);
                    }}
                    variant="contained">详情</Button>
                </> : <></>

              )
            }
          ]}
        />
      </CardContent>
      <CardActions className={classes.actions}>
        <Pagination
          count={Math.ceil(total / 10) || 1}
          page={page}
          onChange={handlePageChange}
          shape="rounded" />
      </CardActions>
    </Card>
  );
};

DataTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired
};

export default DataTable;
