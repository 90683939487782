export default {
  login: "/gsq-api/user-info/login post query", // 登录
  register: "/gsq-api/user-info/register post query", // 注册
  refreshToken: "/gsq-api/user-info/refreshToken get", // 刷新 token
  regenerateAPIToken: "/gsq-api/user-info/regenerateAPIToken get", // 刷新 api token
  setAuth: "/gsq-api/user-info/setupAuthAccount get", // 设置 auth 账号
  updatePassword: "/gsq-api/user-info/resetPassword post query", // 修改密码
  getUser: "/gsq-api/user-info/userInfo get", // 获取用户信息
  notice: "/gsq-api/user-info/notice get", // 系统通知
  grantAdmin: "/gsq-api/user-info/grantAdmin get", // 模拟登录
  userList: "/gsq-api/user-info/listUser get", // 用户列表
  setConfig: "/gsq-api/system/setConfig post", // config 单条
  setConfigs: "/gsq-api/system/setConfigs post", // config all
  allConfig: "/gsq-api/system/allConfig get", // 所有 config
  bindSource: "/gsq-api/user-info/bindSource",

  codeMapList: "/gsq-api/code-map/list get",
  codeMapAdd: "/gsq-api/code-map/add post",
  codeMapUpdate: "/gsq-api/code-map/update post",
  codeMapDelete: "/gsq-api/code-map/delete get",

  orderSearch: "/gsq-api/order/search get",
  orderDetail: "/gsq-api/order/orderDetail get",
  orderStatusEnums: "/gsq-api/order/orderStatusEnums get",
  carrierEnums: "/gsq-api/order/carrierEnums get",
  manualTicket:"/gsq-api/order/manualTicket get",
  setFailed:"/gsq-api/order/setFailed get",
  closeRefundOrder:"/gsq-api/order/closeRefundOrder get",
  approveRefundOrder:"/gsq-api/order/approveRefundOrder get",
  refundInsurance:"/gsq-api/order/refundInsurance get",

  orderSourceDetail:"/gsq-api/orderSource/orderSourceDetail get", // 订单源详情
  addOrUpdatePolicy: "/gsq-api/orderSource/addOrUpdatePolicy post", // 新建一个政策
  createOrderSource: "/gsq-api/orderSource/createOrderSource get", // 创建订单源
  deletePolicy: "/gsq-api/orderSource/deletePolicy get", // 删除调价政策
  listOrderSource: "/gsq-api/orderSource/listOrderSource get", // 订单源列表
  listPolicy: "/gsq-api/orderSource/listPolicy get", // 调价政策列表
  updateOrderSourceDSL: "/gsq-api/orderSource/updateOrderSourceDSL post query", // 修改 dsl 脚本
  updateOrderSourceDesc: "/gsq-api/orderSource/updateOrderSourceDesc get", // 修改订单源描述

  agentList: "/gsq-api/agent/list get",
  createAgent: "/gsq-api/agent/createAgent get",
  updateAgentDesc: "/gsq-api/agent/updateAgentDesc get",
  grantDeveloper: "/gsq-api/agent/grantDeveloper get",
  addUserToAgentGroup: "/gsq-api/agent/addUserToAgentGroup get",
  agentGet: "/gsq-api/agent/getAgent get",
  updateAgentDSL: "/gsq-api/agent/updateAgentDSL form",
  listAgentUser: "/gsq-api/agent/listAgentUser get",
  addUserIntoAgentGroup: "/gsq-api/agent/addUserIntoAgentGroup get",
  removeUserFromAgentGroup: "/gsq-api/agent/removeUserFromAgentGroup get",

  flightSearch: "/gsq-api/gsq/search post",
  flightBooking: "/gsq-api/gsq/booking post",
  flightOrder: "/gsq-api/gsq/createOrder post",
  payValid: "/gsq-api/gsq/payValid post",
  payNotify: "/gsq-api/gsq/payNotify post",

  listProductBundle: "/gsq-api/product-bundles/list get",
  addOrUpdateProductBundle: "/gsq-api/product-bundles/addOrUpdate post",
  deleteProductBundle: "/gsq-api/product-bundles/delete get",
  listBundleProduct: "/gsq-api/product-bundles/products get",

  sysLogSearch: "/gsq-api/sys-log/list get",
  
  orderSupplement: "/gsq-api/order/supplement post",
}
