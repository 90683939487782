import { Button, IconButton, MenuItem, Select, Card, CardContent, Grid, TextField, Typography } from "@material-ui/core";
import { AddCircle, Delete } from '@material-ui/icons';
import apis from "apis";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import moment from "moment/moment";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { OpeDialog } from "components";
import { Table } from 'views/common';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },

  mt: {
    marginTop: theme.spacing(2)
  },

}));

function PolicyInput({ title, editItem, setEditItem, paramKey, placeholder = '', isDate = false }) {
  const classes = useStyles();
  return (
    <Grid item xs={4}>
      <Typography variant="h6">{title}</Typography>
      <TextField
        placeholder={placeholder}
        className={classes.dialogInput}
        size="small"
        variant="outlined"
        type={isDate ? "date" : "text"}
        value={editItem[paramKey]}
        onChange={(e) => setEditItem({
          ...editItem,
          [paramKey]: e.target.value
        })} />
    </Grid>
  )
}



function XItems({ editItem, bundleProductList }) {
  const [showData, setShowData] = useState(editItem.xitemArray);
  const [refresh, setRefresh] = useState(+new Date());
  useEffect(() => {
    setShowData(editItem.xitemArray)
  }, [refresh])

  const classes = useStyles();
  return (
    <>
      <Button
        size="small"
        color="primary"
        className={classes.tableButton}
        style={{ marginTop: 24 }}
        onClick={() => {
          editItem.xitemArray.forEach(o => delete o.added)
          editItem.xitemArray.push({ added: true, type: 'INSURANCE', data: JSON.parse(bundleProductList['INSURANCE'][0].data) })
          setRefresh(+new Date())
        }}
        variant="contained">添加产品</Button>
      <Table columns={[
        {
          label: '类型',
          render: (item) => {
            if (item.added) return (<>
              <Select
                className={classes.inputItem}
                value={item.type}
                onChange={(e) => {
                  item.type = e.target.value;
                }}
              >
                {[{ key: "INSURANCE", label: "保险" }].map(k => (
                  <MenuItem key={k.key} value={k.key}>
                    <pre>{k.label}</pre>
                  </MenuItem>
                ))}

              </Select>
            </>)
            return (<>{item.type === 'INSURANCE' ? '保险' : item.type}</>)
          }
        },
        {
          label: '产品',
          render: (item) => {
            if (item.added) return (<>
              <Select
                className={classes.inputItem}
                onChange={(e) => {
                  item.id = e.target.value
                  item.data = JSON.parse(bundleProductList[item.type].filter(i => i.id === e.target.value)[0].data);
                }}
              >
                {bundleProductList[item.type].map(k => (
                  <MenuItem key={k.id} value={k.id}>
                    <pre>{JSON.parse(k.data).productName}-{JSON.parse(k.data).price}</pre>
                  </MenuItem>
                ))}

              </Select>
            </>)
            return (<>{item.data?.productName}</>)
          }
        },
        {
          label: "价格",
          render: (item) => {
            if (item.added) return (<></>)
            return (<>{item.data?.price}</>)
          }
        },
        {
          label: "操作",
          render: (item, index) => {
            return (
              <>
                <Button
                  size="small"
                  color="primary"
                  className={classes.tableButton}
                  onClick={() => {
                    editItem.xitemArray.splice(index, 1)
                    setRefresh(+new Date())
                  }}
                  variant="contained">删除</Button>
              </>
            )
          }
        }
      ]}
        data={showData}>
      </Table>
    </>
  )
}

function initItems() {
  return {
    xitems: "[]",
    xitemArray: [],
    cabins: "",
    carriers: "",
    collapse: false,
    onlyLowestPrice: true,
    dateBegin: "",
    dateEnd: "",
    depArrs: "",
    note: "",
    ticketDiscountAmount: 0
  }
}


const EditorFragment = (props) => {
  const classes = useStyles();
  const { isEdit, editItem, setEditItem, bundleProductList } = props;
  return (<Card className={classes.mt}>
    <CardContent>
      <Grid
        container
        spacing={3}
        wrap="wrap"
      >
        <PolicyInput title="备注" editItem={editItem} setEditItem={setEditItem}
          paramKey="note" />
        <Grid item xs={4}>
          <Typography variant="h6">折叠</Typography>
          <Select
            className={classes.inputItem}
            value={editItem.collapse}
            onChange={(e) => setEditItem({
              ...editItem,
              collapse: e.target.value
            })}
          >
            {[{ key: false, label: "否  " }, { key: true, label: "是  " }].map(k => (
              <MenuItem key={k.key} value={k.key}>
                <pre>{k.label}</pre>
              </MenuItem>
            ))}

          </Select>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">仅最低价</Typography>
          <Select
            className={classes.inputItem}
            value={editItem.onlyLowestPrice}
            onChange={(e) => setEditItem({
              ...editItem,
              onlyLowestPrice: e.target.value
            })}
          >
            {[{ key: false, label: "否  " }, { key: true, label: "是  " }].map(k => (
              <MenuItem key={k.key} value={k.key}>
                <pre>{k.label}</pre>
              </MenuItem>
            ))}

          </Select>
        </Grid>
        <PolicyInput title="航线(TYN-ALL,)" editItem={editItem} setEditItem={setEditItem}
          paramKey="depArrs" />
        <PolicyInput title="航司(CA,ALL)" editItem={editItem} setEditItem={setEditItem}
          paramKey="carriers" />
        {/* <PolicyInput title="舱等(Y,ALL)" editItem={editItem} setEditItem={setEditItem}
          paramKey="cabins" /> */}
        <PolicyInput title="航班日期起" editItem={editItem} setEditItem={setEditItem} placeholder='yyyy-mm-dd'
          paramKey="dateBegin" />
        <PolicyInput title="航班日期止" editItem={editItem} setEditItem={setEditItem} placeholder='yyyy-mm-dd'
          paramKey="dateEnd" />
        <PolicyInput title="优惠金额（元）" editItem={editItem} setEditItem={setEditItem}
          paramKey="ticketDiscountAmount" />
      </Grid>
      <Grid>
        <XItems editItem={editItem} bundleProductList={bundleProductList} />
      </Grid>
    </CardContent>
  </Card>);
}

const ProductBundleEditorDialog = (props) => {
  const { editSource, setRefresh, openDialog, setOpenDialog, isEdit, bundleProductList } = props;
  const [editItem, setEditItem] = useState(initItems());
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!!editSource) {
      let xitemArray = [];
      if (editSource.xitemArray) {
        editSource.xitemArray.forEach(i => {
          xitemArray.push(i)
        })
      }
      setEditItem({ ...editSource, xitemArray: xitemArray })
    }
  }, [editSource, openDialog])

  return (<OpeDialog
    title={isEdit ? "编辑" : "新增"}
    opeContent={
      <EditorFragment
        bundleProductList={bundleProductList}
        isEdit={isEdit}
        editItem={editItem}
        setEditItem={setEditItem}
      />
    }
    openDialog={openDialog}
    setOpenDialog={setOpenDialog}
    doDialog={
      () => {
        editItem.xitems = JSON.stringify(editItem.xitemArray.filter(o => o.id).map(o => { delete o.added; return o; }))
        apis.addOrUpdateProductBundle({
          ...editItem
        }).then(res => {
          if (res.status === 0) {
            setRefresh(+new Date());
            enqueueSnackbar(isEdit ? '修改成功' : '添加成功', {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            });
            setOpenDialog(false);
          } else {
            enqueueSnackbar(res.message, {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            });
          }
        })
      }
    }
    okText="保存"
    okType="primary" />
  );
}


ProductBundleEditorDialog.propTypes = {
  className: PropTypes.string,
  setRefresh: PropTypes.func.isRequired,
  openDialog: PropTypes.bool.isRequired,
  setOpenDialog: PropTypes.func.isRequired,
};


export default ProductBundleEditorDialog;