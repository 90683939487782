import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import apis from 'apis';
import { OpeDialog, SearchInput } from "components";
import { Button, Card, CardActions, CardContent, TextField, Typography } from "@material-ui/core";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import Pagination from "@material-ui/lab/Pagination";
import { Table } from 'views/common';
import { useSnackbar } from "notistack";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  tableButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {},
  dialog: {
    width: theme.spacing(60)
  },
  dialogInput: {
    width: '100%'
  },
}));


const Account = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [refresh, setRefresh] = useState(+new Date());


  const [openDialog, setOpenDialog] = useState(false);
  const [addUserName, setAddUserName] = useState("");

  const { enqueueSnackbar } = useSnackbar();


  useEffect(() => {
    const getData = () => {
      apis.listAgentUser().then(res => {
        if (res.status === 0) {
          setUsers(res.data);
        }
      }).catch(e => console.log(e));
    }
    getData();
  }, [refresh]);

  const showData = users.filter(item => {
    return JSON.stringify(item).includes(keyword);
  });


  const addNewUserToGroup = (userName, isDeveloper) => {
    apis.addUserIntoAgentGroup({
      userName: userName,
      isDeveloper: !!isDeveloper,
    }).then(res => {
      if (res.status !== 0) {
        enqueueSnackbar(res.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
      } else {
        setOpenDialog(false);
        setRefresh(+new Date());
      }
    });
  }

  const removeUserFromAgentGroup = (userName) => {
    apis.removeUserFromAgentGroup({
      userName: userName,
    }).then(res => {
      if (res.status !== 0) {
        enqueueSnackbar(res.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
      } else {
        setOpenDialog(false);
        setRefresh(+new Date());
      }
    });
  }
  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          onChange={(v) => {
            setKeyword(v);
            setPage(1)
          }}
          placeholder="请输入关键词进行查询"
        />
        <span className={classes.spacer} />
        <Button
          startIcon={<EmojiPeopleIcon />}
          color="primary"
          variant="contained"
          onClick={() => setOpenDialog(true)}
        >
          添加成员
        </Button>
      </div>
      <div className={classes.content}>
        <Card>
          <CardContent className={classes.content}>
            <Table
              data={showData.slice((page - 1) * 10, page * 10)}
              columns={[
                {
                  label: '账号',
                  key: 'userName'
                }, {
                  label: '密码',
                  key: 'passwd'
                },
                {
                  label: '所属代理商',
                  key: 'agent'
                }, {
                  label: '代理商管理员',
                  render: (item) => (
                    item.agentDeveloper ? (<p>是</p>) : (<p>否</p>)
                  )
                }, {
                  label: '操作',
                  render: (item) => (
                    <>
                      <Button
                        startIcon={<MotorcycleIcon style={{ fontSize: 16 }} />}
                        size="small"
                        color="primary"
                        className={classes.tableButton}
                        onClick={() => addNewUserToGroup(item.userName, !item.agentDeveloper)}
                        variant="contained">{item.agentDeveloper ? "移除代理商管理员" : "设置代理商管理员"}</Button>

                      <Button
                        startIcon={<MotorcycleIcon style={{ fontSize: 16 }} />}
                        size="small"
                        color="primary"
                        className={classes.tableButton}
                        onClick={() => removeUserFromAgentGroup(item.userName)}
                        variant="contained">移除成员</Button>


                    </>
                  )
                }
              ]}
            />
          </CardContent>
          <CardActions className={classes.actions}>
            <Pagination
              count={Math.ceil(showData.length / 10) || 1}
              page={page}
              onChange={(event, page) => {
                setPage(page);
              }}
              shape="rounded" />
          </CardActions>
          <OpeDialog
            maxWith={"lg"}
            fullWith={true}
            title={"添加账户"}
            opeContent={(<>
              <Typography gutterBottom variant="h6">账户名</Typography>
              <TextField
                className={classes.inputItem}
                size="small"
                variant="outlined"
                placeholder="账户名"
                value={addUserName}
                onChange={(e) => setAddUserName(e.target.value)} />
            </>
            )}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            doDialog={() => addNewUserToGroup(addUserName, false)}
            okText="确认"
            okType="primary"
          />
        </Card>
      </div>
    </div>
  );
};

export default Account;
