import uri from "./uri";
import axios from "axios";

const prefix = "";

let timer = null;

let reqs = {
    getStore: (isAdmin) => {
        const user = JSON.parse(localStorage.getItem("Gsq-USER") || "{}");
        if (isAdmin) {
            return user;
        }
        const userMock = JSON.parse(localStorage.getItem("Gsq-USER-MOCK") || "{}");
        return userMock.mock ? userMock : user;
    },
    setStore: (user, key) => {
        const userMock = JSON.parse(localStorage.getItem("Gsq-USER-MOCK") || "{}");
        key = key || (userMock.mock ? "Gsq-USER-MOCK" : "Gsq-USER");
        localStorage.setItem(key, JSON.stringify(user));
    }
};

function redirectToLogin() {
    localStorage.removeItem("Gsq-USER");
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
        let href = window.location.href;
        if (href.indexOf("/#/sign-in") < 0) {
            localStorage.setItem("KgAfterSignTarget", href);
        }
        window.location.href = "/#/sign-in";
    }, 100);
}

function doGet(uri, params = "", route = false) {
    if (route) {
        uri += params ? ("/" + params) : "";
    } else {
        // 组装参数
        let p = [];
        for (let i of Object.keys({...params})) {
            if (params[i] != null) {
                let key = encodeURIComponent(i);
                let value = encodeURIComponent(params[i]);
                p.push(`${key}=${value}`);
            }
        }
        if (p.length > 0) {
            uri += "?" + p.join("&");
        }
    }
    let user = reqs.getStore();
    return new Promise((resolve) => {
        axios({
            method: "get",
            url: prefix + uri,
            headers: {
                KgToken: user.loginToken
            }
        })
            .then((response) => {
                if (response.data.status === -1 && response.data.message === "请重新登录") {
                    redirectToLogin();
                    return;
                }
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    resolve(error.response?.data);
                }
            });
    });
}

function doPost(uri, data, query) {
    // 组装参数
    let p = [];
    for (let i of Object.keys({...data})) {
        if (data[i]) {
            let key = encodeURIComponent(i);
            let value = encodeURIComponent(data[i]);
            p.push(`${key}=${value}`);
        }
    }
    let user = reqs.getStore();
    return new Promise((resolve) => {
        axios({
            method: "post",
            url: prefix + uri,
            data: query ? p.join("&") : data,
            headers: {
                KgToken: user.loginToken
            }
        })
            .then((response) => {
                if (response.data.status === -1 && response.data.message === "请重新登录") {
                    redirectToLogin();
                    return;
                }
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    resolve(error.response?.data);
                }
            });
    });
}

function doForm(uri, data) {
    // 组装参数
    let form = new FormData();
    for (let i of Object.keys({...data})) {
        if (data[i]) {
            form.append(i, data[i]);
        }
    }
    let user = reqs.getStore();
    return new Promise((resolve) => {
        axios({
            method: "post",
            url: prefix + uri,
            data: form,
            headers: {
                KgToken: user.loginToken
            }
        })
            .then((response) => {
                if (response.data.status === -1 && response.data.message === "请重新登录") {
                    redirectToLogin();
                    return;
                }
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    resolve(error.response?.data);
                }
            });
    });
}

for (let i of Object.keys(uri)) {
    const [url, method, query] = uri[i].split(" ");
    if (method === "post") {
        reqs[i] = (body) => doPost(url, body, query);
    } else if (method === "form") {
        reqs[i] = (body) => doForm(url, body);
    } else {
        reqs[i] = (params) => doGet(url, params, query);
    }
}

export default reqs;
