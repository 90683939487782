import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Input,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import moment from "moment";
import apis from "apis";

const useStyles = makeStyles(theme => ({
  root: {},
  inputItem: {
    width: "100%"
  },
  pos: {
    marginBottom: theme.spacing(2)
  },
  miniMargin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  inline: {
    display: "inline-block"
  }
}));

const defaultSearchEnum = {
  "label": "全部",
  "key": "ALL"
};

const SearchFilter = props => {
  const classes = useStyles();
  const { className, setRefresh, searchForm, setSearchForm, ...rest } = props;

  const [orderStatus, setOrderStatus] = useState([defaultSearchEnum]);

  useEffect(() => {
    apis.orderStatusEnums().then(res => {
      let orderStatus = res.data;
      orderStatus.unshift({
        "label": "全部",
        "key": "ALL"
      });
      setOrderStatus(orderStatus);
    })
  }, [])

  const [carrierEnums, setCarrierEnums] = useState([defaultSearchEnum])
  useEffect(() => {
    apis.carrierEnums().then(res => {
      let carriers = res.data;
      carriers.unshift({
        "label": "全部",
        "key": "ALL"
      });
      setCarrierEnums(carriers);
    })
  }, [])

  const fromDateRef = useRef(null);
  const toDateRef = useRef(null);
  const depDateRef = useRef(null);
  const orderNoRef = useRef(null);
  const phoneRef = useRef(null);
  const passengerNameRef = useRef(null);
  const carrierRef = useRef(null);
  const orderStatusRef = useRef(null);
  const orderSourceRef = useRef(null);

  return (
    <Card {...rest}
      className={clsx(classes.pos, className)}>
      <CardHeader
        title="订单查询面板"
      />
      <CardContent>
        <Grid
          container
          spacing={9}
          wrap="wrap"
        >
          <Grid item xs={4}>
            <Typography
              gutterBottom
              variant="h6"
            >订单起始日期</Typography>
            <TextField
              inputRef={fromDateRef}
              style={{ width: '100%' }}
              type="date"
              value={searchForm.fromDate}
              onChange={e => {
                let pickedDate = (e.target.value) ? moment(e.target.value).format("YYYY-MM-DD") : ''
                setSearchForm({
                  ...searchForm,
                  fromDate: pickedDate,
                  pageNo: 1
                });
                // setRefresh(+new Date());
                return true;
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography
              gutterBottom
              variant="h6"
            >订单结束日期</Typography>
            <TextField
              inputRef={toDateRef}
              style={{ width: '100%' }}
              type="date"
              value={searchForm.toDate}
              onChange={e => {
                let pickedDate = (e.target.value) ? moment(e.target.value).format("YYYY-MM-DD") : ''
                setSearchForm({
                  ...searchForm,
                  toDate: pickedDate,
                  pageNo: 1
                });
                // setRefresh(+new Date());
                return true;
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography
              gutterBottom
              variant="h6"
            >客人出行日期</Typography>
            <TextField
              inputRef={depDateRef}
              style={{ width: '100%' }}
              type="date"
              value={searchForm.depDate}
              onChange={e => {
                let pickedDate = (e.target.value) ? moment(e.target.value).format("YYYY-MM-DD") : ''
                setSearchForm({
                  ...searchForm,
                  depDate: pickedDate,
                  pageNo: 1
                });
                // setRefresh(+new Date());
                return true;
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Grid>
        <Divider className={classes.miniMargin} />
        <Grid
          container
          spacing={9}
          wrap="wrap"
        >
          <Grid item xs={4}>
            <Typography
              gutterBottom
              variant="h6"
            >订单来源</Typography>
            <Input
              inputRef={orderSourceRef}
              className={classes.inputItem}
              onChange={(v) => {
                setSearchForm({
                  ...searchForm,
                  "orderSource": v.target.value,
                  pageNo: 1
                });
                // setRefresh(+new Date());
              }}
              value={searchForm.orderSource}
              placeholder="请输入订单来源"
            />

          </Grid>
          <Grid item xs={4}>
            <Typography
              gutterBottom
              variant="h6"
            >航司</Typography>
            <Select
              ref={carrierRef}
              className={classes.inputItem}
              value={searchForm.carrier}
              onChange={(e) => {
                setSearchForm({
                  ...searchForm,
                  "carrier": e.target.value,
                  pageNo: 1
                });
                // setRefresh(+new Date());
              }}
            >
              {carrierEnums.map(k => (
                <MenuItem key={k.key} value={k.key}>
                  <pre>{k.label}</pre>
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Divider className={classes.miniMargin} />
        <Grid
          container
          spacing={2}
          wrap="wrap"
        >
          <Grid item xs={4}>
            <Input
              inputRef={orderNoRef}
              label="订单号"
              className={classes.inputItem}
              onChange={(v) => {
                setSearchForm({
                  ...searchForm,
                  "orderNo": v.target.value,
                  pageNo: 1
                });
                // setRefresh(+new Date());
              }}
              value={searchForm.orderNo}
              placeholder="请输入订单号"
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              inputRef={phoneRef}
              label="手机号"
              className={classes.inputItem}
              onChange={(v) => {
                setSearchForm({
                  ...searchForm,
                  "phone": v.target.value,
                  pageNo: 1
                });
                // setRefresh(+new Date());
              }}
              value={searchForm.phone}
              placeholder="请输入手机号"
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              inputRef={passengerNameRef}
              label="乘客姓名"
              className={classes.inputItem}
              onChange={(v) => {
                setSearchForm({
                  ...searchForm,
                  "passengerName": v.target.value,
                  pageNo: 1
                });
                // setRefresh(+new Date());
              }}
              value={searchForm.passengerName}
              placeholder="请输入姓名"
            />
          </Grid>
        </Grid>
        <Divider className={classes.miniMargin} />
        <Button variant="contained" color="primary" 
          onClick={() => {
            setRefresh(+new Date());
          }}
        >搜索</Button>
        <Button variant="contained" color="primary" style={{ marginLeft: 10 }}
          onClick={() => {
            setSearchForm({
              ...searchForm,
              fromDate: null,
              toDate: null,
              depDate: null,
              orderNo: null,
              phone: null,
              passengerName: null,
              carrier: 'ALL',
              orderStatus: 'ALL',
              orderSource: null,
            })
            fromDateRef.current.value = '';
            toDateRef.current.value = '';
            depDateRef.current.value = '';
            orderNoRef.current.value = '';
            phoneRef.current.value = '';
            passengerNameRef.current.value = '';
            carrierRef.current.value = 'ALL';
            orderStatusRef.current.value = 'ALL';
            orderSourceRef.current.value = '';
          }}
        >重置</Button>

      </CardContent>
    </Card>
  );
};


SearchFilter.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object,
  searchForm: PropTypes.object,
  setSearchForm: PropTypes.func
};
export default SearchFilter;