import {Card, CardContent, CardHeader, Button, Backdrop, Divider} from "@material-ui/core";
import {Table} from 'views/common';
import React from "react";
import {makeStyles} from "@material-ui/styles";
import apis from 'apis';
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
    mt: {
        marginTop: theme.spacing(2)
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const CHANGE_ORDER_STATUS = {
  "INIT": "订单创建",
    "USER_PAY": "用户已支付",
    "AGENT_SUBMIT_FAILED": "提交给代理商失败",
    "AGENT_FEE_ERROR": "代理商费用错误",
    "AGENT_REJECT": "代理商拒绝退票",
    "AGENT_OK": "代理商确认订单",
    "FINISHED": "代理商处理完成",
}

const ChangeFragment = (props) => {
    const {order, reload} = props;
    const classes = useStyles();

    return (
        <Card className={classes.mt}>
            <CardHeader title="改签记录"/>
            <CardContent>
                <Table columns={[
                    {
                        label: '改签单号',
                        render: (item) => {
                            return item.orderChange.changeOrderNo;
                        }
                    },
                    {
                        label: '代理商改签单号',
                        render: (item) => {
                            return item.orderChange.agentChangeOrderNo;
                        }
                    }, {
                        label: "改签单状态",
                        render: (item) => {
                            return CHANGE_ORDER_STATUS[item.orderChange.changeOrderStatus];
                        }
                    }, {
                        label: "申请时间",
                        render: (item) => {
                            return item.orderChange.createTime;
                        }
                    }
                ]}
                       data={order.changeList}
                       collapse={true}
                       renderCollapse={(item) => (
                           <>
                              <Table 
                                data= {item.orderChangeTicketList}
                                columns={
                                   [
                                       {
                                           label: '乘客姓名',
                                           key: 'userName'
                                       },
                                       {
                                          label: '证件号',
                                          key: 'cardNo'
                                       },
                                       {
                                          label: '原航班号',
                                          key: 'flightNo'
                                       },
                                       {
                                          label: '新航班号',
                                          key: 'newFlightNo'
                                       },
                                       {
                                          label: '新票号',
                                          key: 'newTicketNo'
                                       }
                                   ]
                               }/>
                              <Divider />
                              <Table 
                                data= {item.orderChangeFlightList}
                                columns={
                                   [
                                       {
                                           label: '新航班号',
                                           key: 'flightNo'
                                       },
                                       {
                                          label: '出发',
                                          key: 'depName'
                                       },
                                       {
                                          label: '到达',
                                          key: 'arrName'
                                       },
                                       {
                                          label: '仓位',
                                          key: 'cabin'
                                       },
                                       {
                                          label: '起飞时间',
                                          render: (item) => {
                                            return item.depDate + " " + item.dptTime;
                                        }
                                       }
                                   ]
                               }/>
                           </>
                       )}
                >
                </Table>
            </CardContent>
        </Card>
    );
}

export default ChangeFragment;