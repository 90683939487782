import {Button, Card, CardContent, CardHeader, TextField, Typography} from "@material-ui/core";
import {Table} from "views/common";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/styles";
import apis from "apis";
import {OpeDialog} from "components";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
    mt: {
        marginTop: theme.spacing(2)
    },
}));

const TicketPanel = (props) => {
    const {order, reload} = props;
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [opTicket, setOpTicket] = useState({});
    const [openManualTicketDialog, setOpenManualTicketDialog] = useState(false);

    return (
        <Card className={classes.mt}>
            <CardHeader title={"票号操作台"}/>
            <CardContent>
                <Table columns={[
                    {
                        label: '航班号',
                        key: 'flightNo'
                    },
                    {
                        label: '乘机人证件号',
                        key: 'cardNo'
                    },
                    {
                        label: '票号',
                        key: 'ticketNo'
                    },
                    {
                        label: '机票状态',
                        render: (item) => {
                            if (!!item.outboundTicketStatus) {
                                return (
                                    <ul>
                                        <li>销售端:{item.outboundTicketStatus}</li>
                                        <li>代理端:{item.inboundTicketStatus}</li>
                                    </ul>
                                )
                            } else {
                                return (<p>{item.inboundTicketStatus}</p>)
                            }
                        }
                    }
                    ,
                    {
                        label: '出票时间',
                        key: 'ticketTime'
                    }
                    ,
                    {
                        label: '退票时间',
                        key: 'refundTime'
                    }
                    ,
                    {
                        label: '退票手续费',
                        render: (item) => {
                            if (!!item.outboundRefundAmount) {
                                return (
                                    <ul>
                                        <li>销售端:{item.outboundRefundAmount}</li>
                                        <li>代理端:{item.inboundRefundAmount}</li>
                                    </ul>
                                )
                            } else {
                                return (<p>{item.inboundRefundAmount}</p>)
                            }
                        }
                    }
                    ,
                    {
                        label: '改期时间',
                        key: 'changeTime'
                    }
                    ,
                    {
                        label: '改期手续费',
                        render: (item) => {
                            if (!!item.outboundChangeAmount) {
                                return (
                                    <ul>
                                        <li>销售端:{item.outboundChangeAmount}</li>
                                        <li>代理端:{item.inboundChangeAmount}</li>
                                    </ul>
                                )
                            } else {
                                return (<p>{item.inboundChangeAmount}</p>)
                            }
                        }
                    }, {
                        label: '操作',
                        render: (item) => (
                            <>
                                <Button
                                    startIcon={<MotorcycleIcon style={{fontSize: 16}}/>}
                                    size="small"
                                    color="primary"
                                    onClick={() => {
                                        setOpTicket(item);
                                        setOpenManualTicketDialog(true);
                                    }}
                                    variant="contained">回帖票号</Button>
                            </>
                        )
                    },
                ]}
                       data={order.ticketList}>
                </Table>
            </CardContent>
            <OpeDialog
                title="人工回帖票号"
                opeContent={(
                    <>
                        <Typography gutterBottom variant="h6">请输入{opTicket.flightNo} - {opTicket.cardNo}的票号</Typography>
                        <TextField
                            className={classes.dialogInput}
                            size="small"
                            variant="outlined"
                            value={opTicket.ticketNo}
                            onChange={(e) => {
                                setOpTicket({
                                    ...opTicket,
                                    ticketNo: e.target.value
                                })
                            }}/>

                    </>
                )}
                openDialog={openManualTicketDialog}
                setOpenDialog={setOpenManualTicketDialog}
                doDialog={() => {
                    return apis.manualTicket({
                        orderNo: opTicket.orderNo,
                        flightNo: opTicket.flightNo,
                        passengerCardId: opTicket.cardNo,
                        ticketNo: opTicket.ticketNo
                    }).then(res => {
                        if (res.status === 0) {
                            reload();
                            return;
                        }
                        enqueueSnackbar(res.msg, {
                            variant: 'error',
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            },
                        });
                    }).catch((e) => {
                        enqueueSnackbar(e.msg, {
                            variant: 'error',
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            },
                        })
                    });
                }}
                okText="保存"
                okType="primary"/>
        </Card>
    );
}

export default TicketPanel;