import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { SearchInput, OpeDialog } from 'components';
import { 
  Button,
  TextField,
  Typography,
  Grid
 } from '@material-ui/core';
import { Table } from './components';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import apis from 'apis';
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flex: 1
  },
  content: {
    marginTop: theme.spacing(2)
  },
  dialogInput: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
}));

const OrderList = (props) => {
  const {onSelected,selected} = props;
  const isSelector = !!onSelected
  const classes = useStyles();

  const [showData, setShowData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [editItem, setEditItem] = useState({});

  const [refresh, setRefresh] = useState(+new Date());

  useEffect(() => {
    const getData = () => {
      apis.listOrderSource()
        .then(res => {
          if (res.status === 0) {
            setShowData(res.data);
            setTotal(res.data.length);
          }
        }).catch(e => console.log(e));
    }
    getData();
  }, [refresh]);

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          onChange={(v) => console.log(v)}
          placeholder="请输入关键词进行查询"
        />
        {!isSelector ? (
          <>
        <span className={classes.spacer} />
        <Button
          startIcon={<QueuePlayNextIcon />}
          color="primary"
          variant="contained"
          onClick={() => setOpenDialog(true)}
        >
          添加配置
        </Button></>
        ) : null}
      </div>
      <div className={classes.content}>
        <Table
          selectedItem={selected}
          onSelected={onSelected}
          data={showData}
          total={total}
          pageState={[page, setPage]}
          setRefresh={setRefresh} />
      </div>
      <OpeDialog
        title="添加订单源"
        opeContent={(
          <>
            <Grid
              container
              spacing={6}
              wrap="wrap"
            >
              <Grid item xs={12}>
                <Typography gutterBottom variant="h6">订单源ID</Typography>
                <TextField
                  className={classes.dialogInput}
                  gutterBottom
                  size="small"
                  variant="outlined"
                  value={editItem.orderSourceId}
                  onChange={(e) => setEditItem({
                    ...editItem,
                    orderSourceId: e.target.value
                  })} />
                <Typography gutterBottom variant="h6">订单源描述</Typography>
                <TextField
                  className={classes.dialogInput}
                  size="small"
                  variant="outlined"
                  value={editItem.orderSourceDesc}
                  onChange={(e) => setEditItem({
                    ...editItem,
                    orderSourceDesc: e.target.value
                  })} />
              </Grid>
            </Grid>
          </>
        )}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        doDialog={() => {
          return apis.createOrderSource(editItem).then(res => {
            if (res.status === 0) {
              setEditItem({})
              setRefresh(+new Date());
              return '操作成功';
            }
            throw new Error(res.message);
          });
        }}
        okText="保存"
        okType="primary" />
    </div>
  );
};

OrderList.propTypes = {
  onSelected: PropTypes.func,
  selected:PropTypes.string
}

export default OrderList;
