import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { SearchInput, OpeDialog } from 'components';
import {
  Button,
  TextField,
  Typography,
  Grid
} from '@material-ui/core';
import { AppContext } from "adapter";

import clsx from 'clsx';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import apis from 'apis';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {},
  dialog: {
    width: theme.spacing(60)
  },
  dialogInput: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
}));

const Toolbar = props => {
  const { className, onInputChange, setRefresh, ...rest } = props;
  const classes = useStyles();

  const { user } = useContext(AppContext);
  const [editItem, setEditItem] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          onChange={(v) => onInputChange(v)}
          placeholder="请输入关键词进行查询"
        />
        {user.isAdmin ?
          <>
            <span className={classes.spacer} />
            <Button
              startIcon={<QueuePlayNextIcon />}
              color="primary"
              variant="contained"
              onClick={() => setOpenDialog(true)}
            >
              添加三字码配置
            </Button>
          </>
          : null
        }
      </div>
      <OpeDialog
        title="添加CodeMap"
        opeContent={(
          <>
            <Grid
              container
              spacing={6}
              wrap="wrap"
            >
              <Grid item xs={12}>
                <Typography gutterBottom variant="h6">机场code</Typography>
                <TextField
                  className={classes.dialogInput}
                  size="small"
                  variant="outlined"
                  value={editItem.airportCode}
                  onChange={(e) => setEditItem({
                    ...editItem,
                    airportCode: e.target.value
                  })} />
                <Typography gutterBottom variant="h6">城市code</Typography>
                <TextField
                  className={classes.dialogInput}
                  size="small"
                  variant="outlined"
                  value={editItem.cityCode}
                  onChange={(e) => setEditItem({
                    ...editItem,
                    cityCode: e.target.value
                  })} />
                <Typography gutterBottom variant="h6">机场名称</Typography>
                <TextField
                  className={classes.dialogInput}
                  size="small"
                  variant="outlined"
                  value={editItem.airportName}
                  onChange={(e) => setEditItem({
                    ...editItem,
                    airportName: e.target.value
                  })} />
                <Typography gutterBottom variant="h6">机场名简写</Typography>
                <TextField
                  className={classes.dialogInput}
                  size="small"
                  variant="outlined"
                  value={editItem.airportNameShorten}
                  onChange={(e) => setEditItem({
                    ...editItem,
                    airportNameShorten: e.target.value
                  })} />
                <Typography gutterBottom variant="h6">城市名称</Typography>
                <TextField
                  className={classes.dialogInput}
                  size="small"
                  variant="outlined"
                  value={editItem.cityName}
                  onChange={(e) => setEditItem({
                    ...editItem,
                    cityName: e.target.value
                  })} />
                <Typography gutterBottom variant="h6">城市行政区划码</Typography>
                <TextField
                  className={classes.dialogInput}
                  size="small"
                  variant="outlined"
                  value={editItem.xzqhCode}
                  onChange={(e) => setEditItem({
                    ...editItem,
                    xzqhCode: e.target.value
                  })} />
              </Grid>
            </Grid>
          </>
        )}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        doDialog={() => {
          return apis.codeMapAdd(editItem).then(res => {
            if (res.status === 0) {
              setEditItem({})
              setRefresh(+new Date());
              return '操作成功';
            }
            throw new Error(res.message);
          });
        }}
        okText="保存"
        okType="primary" />
    </div>
  );
};

export default Toolbar;
