import {makeStyles} from "@material-ui/styles";
import {Card, CardContent, CardHeader, Collapse, IconButton} from "@material-ui/core";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Table} from "../../common";
import moment from "moment/moment";
import React from "react";

const useStyles = makeStyles(theme => ({
    mt: {
        marginTop: theme.spacing(2)
    },
}));

const LogPanel = (props)=>{
    const {order} = props;
    const classes = useStyles();
    const [logExpanded, setLogExpanded] = React.useState(true);

    return (
        <Card className={classes.mt}>
            <CardHeader title="操作日志"
                        action={(
                            <IconButton
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: logExpanded,
                                })}
                                onClick={() => setLogExpanded(!logExpanded)}
                                aria-expanded={logExpanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon/>
                            </IconButton>
                        )}
            />
            <Collapse in={logExpanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Table columns={[
                        {
                            label: '时间',
                            render: (item) => moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')
                        },
                        {
                            label: '操作',
                            key: 'operation'
                        },
                        {
                            label: '操作员',
                            key: 'operator'
                        },
                    ]}
                           data={order.orderOperateLogList}>
                    </Table>
                </CardContent>
            </Collapse>
        </Card>
    );
}

export default LogPanel;