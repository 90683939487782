import {Card, CardContent, CardHeader, Button, Backdrop,TextField,Dialog,DialogActions,DialogContent,DialogTitle} from "@material-ui/core";
import {Table} from 'views/common';
import React,{useState} from "react";
import {makeStyles} from "@material-ui/styles";
import apis from 'apis';
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
    mt: {
        marginTop: theme.spacing(2)
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const REFUND_ORDER_STATUS = {
  "INIT": "订单创建",
  "AGENT_SUBMIT_FAILED":  "提交给代理商失败",
  "AGENT_FEE_ERROR":  "代理商费用错误",
  "AGENT_REJECT":  "代理商拒绝退票",
  "ADMIN_CLOSED":  "管理员作废此退票单",
  "AGENT_OK":  "代理商确认订单",
  "FINISHED":  "代理商处理完成，资金7个工作日内原路退回",
}

const REFUND_TYPES = {
  "Voluntarily": "自愿退票",
  "Intercept": "出票前拦截出票",
  "FlightChange": "航变退票",
  "Sick": "病退",
  "SpecialEvent": "特殊事件",
}

const RefundFragment = (props) => {
    const {order, reload} = props;
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [showAttachements, setShowAttachements] = React.useState(false);
    const [showRefundModal, setShowRefundModal] = useState(false);
    const [attachments, setAttachments] = React.useState([]);
    const [loading, setLoading] = useState(false);
    const [refundDetails, setRefundDetails] = useState({
        supplementMoney: '',
        supplementReason: ''
      });

    const handleClickOpen = () => {
        setShowRefundModal(true);
      };
    
      const handleClose = () => {
        setRefundDetails({
          supplementMoney: '',
          supplementReason: '',
          refundOrderId:''
        });
        setShowRefundModal(false);
        setLoading(false)
      };
 // 状态用于存储输入框的值

  // 处理输入字段变更
  const handleChange = (e) => {
    const { id, value } = e.target;
    setRefundDetails({
      ...refundDetails,
      [id]: value
    });
  };

  // 提交表单
  const handleSubmit = async() => {
    setLoading(true)
    // 校验金额必填
    if (!refundDetails.supplementMoney) {
      alert('补退金额是必填项');
      setLoading(false)
      return;
    }
    const data =await apis.orderSupplement({...refundDetails})
    if(data?.ok){
        enqueueSnackbar(data.data, {
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
        });
    }else{
        enqueueSnackbar(data.message, {
            variant: 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
        });
    }
    setLoading(false)
    handleClose(); // 关闭模态框
    // 在这里可以执行进一步的操作，例如发送数据到服务器
  };
    return (
        <Card className={classes.mt}>
            <CardHeader title="退票记录"/>
            <CardContent>
                <Table columns={[
                    {
                        label: '退票单号',
                        render: (item) => {
                            return item.orderRefund.refundOrderNo;
                        }
                    },
                    {
                        label: '代理商退票单号',
                        render: (item) => {
                            return item.orderRefund.agentRefundOrderNo;
                        }
                    },
                    {
                        label: "退票金额",
                        render: (item) => {
                            return item.orderRefund.outboundRefundAmount;
                        }
                    }, {
                        label: "退票费用",
                        render: (item) => {
                            return item.orderRefund.outboundRefundFee;
                        }
                    }, {
                        label: "退票类型",
                        render: (item) => {
                            return REFUND_TYPES[item.orderRefund.refundType];
                        }
                    }, {
                        label: "退票原因",
                        render: (item) => {
                            return item.orderRefund.refundReason;
                        }
                    }, {
                        label: "退票单状态",
                        render: (item) => {
                            return REFUND_ORDER_STATUS[item.orderRefund.refundOrderStatus];
                        }
                    }, {
                        label: "申请时间",
                        render: (item) => {
                            return item.orderRefund.createTime;
                        }
                    }, {
                        label: "证明材料",
                        render: (item) => {
                          if (item.attachments.length > 0) {
                            return (
                              <Button
                                size="small"
                                color="primary"
                                onClick={() => {
                                  setAttachments(item.attachments);
                                  setShowAttachements(true);
                                }}
                                variant="contained">查看</Button>
                            )
                          }
                        }
                    }, {
                      label: '操作',
                      render: (item) => {
                        if (item.orderRefund.refundOrderStatus === 'FINISHED') {
                          return <Button  
                                   size="small"
                                   color="primary"
                                   variant="contained"
                                   onClick={()=>{
                                    setRefundDetails({
                                      ...refundDetails,
                                      refundOrderId:item.orderRefund.refundOrderNo
                                    });
                                    handleClickOpen()
                                   }}
                                   >补退</Button>
                        }
                        if (item.orderRefund.refundOrderStatus !== 'FINISHED' && item.orderRefund.refundOrderStatus !== 'ADMIN_CLOSED') {
                          return (
                          <>
                              <Button
                                  size="small"
                                  color="primary"
                                  onClick={() => {
                                    // TODO: implement this function
                                    apis.closeRefundOrder({
                                      orderNo: order.orderInfo.orderNo, 
                                      refundOrderNo: item.orderRefund.refundOrderNo
                                    }).then(res => {
                                      if (res.status === 0) {
                                        reload();
                                        return;
                                    }
                                    enqueueSnackbar(res.msg, {
                                        variant: 'error',
                                        anchorOrigin: {
                                            vertical: 'top',
                                            horizontal: 'center',
                                        },
                                    });
                                }).catch((e) => {
                                    enqueueSnackbar(e.msg, {
                                        variant: 'error',
                                        anchorOrigin: {
                                            vertical: 'top',
                                            horizontal: 'center',
                                        },
                                    })
                                });
                                }}
                                  variant="contained">作废</Button>
                              <Button
                                  size="small"
                                  color="primary"
                                  onClick={() => {
                                    // TODO: implement this function
                                    apis.approveRefundOrder({
                                      orderNo: order.orderInfo.orderNo, 
                                      refundOrderNo: item.orderRefund.refundOrderNo
                                    }).then(res => {
                                      if (res.status === 0) {
                                        reload();
                                        return;
                                    }
                                    enqueueSnackbar(res.msg, {
                                        variant: 'error',
                                        anchorOrigin: {
                                            vertical: 'top',
                                            horizontal: 'center',
                                        },
                                    });
                                }).catch((e) => {
                                    enqueueSnackbar(e.msg, {
                                        variant: 'error',
                                        anchorOrigin: {
                                            vertical: 'top',
                                            horizontal: 'center',
                                        },
                                    })
                                });
                                }}
                                  variant="contained">批准(危险操作，不清楚用法须先咨询志洸)</Button>
                          </>)
                        } else {
                          return <></>
                        }
                        
                      }
                    }
                ]}
                       data={order.refundList}
                       collapse={true}
                       renderCollapse={(item) => (
                           <>
                               <Table 
                                data= {item.orderRefundTicketList}
                                columns={
                                   [
                                       {
                                           label: '乘客姓名',
                                           key: 'userName'
                                       },
                                       {
                                          label: '证件号',
                                          key: 'cardNo'
                                       },
                                       {
                                          label: '航班号',
                                          key: 'flightNo'
                                       },
                                       {
                                          label: '退票金额',
                                          key: 'outboundRefundAmount'
                                       },
                                       {
                                          label: '退票费用',
                                          key: 'outboundRefundFee'
                                       }
                                   ]
                               }/>
                           </>
                       )}
                >
                </Table>
            </CardContent>
            <Backdrop className={classes.backdrop} open={showAttachements} onClick={() => setShowAttachements(false)}>
              {attachments.map((item, index) => {
                return (
                  <img key={index} src={item.contentBin} style={{maxWidth: '100%'}} alt={item.fileName}/>
                )
              })}
            </Backdrop>
            <Dialog open={showRefundModal} onClose={handleClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">补退信息</DialogTitle>
              <DialogContent>
                 <TextField
                   autoFocus
                   required
                   margin="dense"
                   id="supplementMoney"
                   label="补退金额(元)"
                   type="text"
                   fullWidth
                   value={refundDetails.supplementMoney}
                   onChange={handleChange}
                 />
                <TextField
                    margin="dense"
                    id="supplementReason"
                    label="备注"
                    type="text"
                    fullWidth
                    value={refundDetails.supplementReason}
                    onChange={handleChange}
                 />
               </DialogContent>
                 <DialogActions>
               <Button onClick={handleClose} color="primary">
                  取消
               </Button>
               <Button onClick={loading?null:handleSubmit} color="primary" disabled={loading}>
                 确定
               </Button>
             </DialogActions>
           </Dialog>
        </Card>
    );
}

export default RefundFragment;