//http://m.nnn666.com/qiming/12944.html
let randomAdult = [
    {"name": "郭山彤", "cardType": "NI", "cardNo": "350781196403070163", "birthday": "1964-03-07", "cardExpire": "", "phone": ""},
    {"name": "梁敦厦", "cardType": "NI", "cardNo": "330781199307124395", "birthday": "1993-07-12", "cardExpire": "", "phone": ""},
    {"name": "霍负浪", "cardType": "NI", "cardNo": "360826198507137037", "birthday": "1985-07-13", "cardExpire": "", "phone": ""},
    {"name": "虞信品", "cardType": "NI", "cardNo": "522324199610167580", "birthday": "1996-10-16", "cardExpire": "", "phone": ""},
    {"name": "马仁毅", "cardType": "NI", "cardNo": "370831199510131821", "birthday": "1995-10-13", "cardExpire": "", "phone": ""},
    {"name": "冯州龙", "cardType": "NI", "cardNo": "640522198208135133", "birthday": "1982-08-13", "cardExpire": "", "phone": ""},
    {"name": "程梦琪", "cardType": "NI", "cardNo": "360725198008164688", "birthday": "1980-08-16", "cardExpire": "", "phone": ""},
    {"name": "沈忆柳", "cardType": "NI", "cardNo": "542226196105135411", "birthday": "1961-05-13", "cardExpire": "", "phone": ""},
    {"name": "孙尔岚", "cardType": "NI", "cardNo": "621026198208294586", "birthday": "1982-08-29", "cardExpire": "", "phone": ""},
    {"name": "吕元香", "cardType": "NI", "cardNo": "410927198407110036", "birthday": "1984-07-11", "cardExpire": "", "phone": ""},
    {"name": "赵初夏", "cardType": "NI", "cardNo": "360622198801179813", "birthday": "1988-01-17", "cardExpire": "", "phone": ""}
];

let randomChild = [
    {"name": "孙剑佛", "cardType": "NI", "cardNo": "340711201608121350", "birthday": "2016-08-12", "cardExpire": "", "phone": ""},
    {"name": "车杜炯", "cardType": "NI", "cardNo": "440515201711238918", "birthday": "2017-11-23", "cardExpire": "", "phone": ""},
    {"name": "蔡粮鲜", "cardType": "NI", "cardNo": "371523201807174608", "birthday": "2018-07-17", "cardExpire": "", "phone": ""},
    {"name": "陈乐枫", "cardType": "NI", "cardNo": "350724201609037111", "birthday": "2016-09-03", "cardExpire": "", "phone": ""},
    {"name": "刘晓霜", "cardType": "NI", "cardNo": "13052520170923087X", "birthday": "2017-09-23", "cardExpire": "", "phone": ""},
    {"name": "谢千兰", "cardType": "NI", "cardNo": "620102201804102948", "birthday": "2018-04-10", "cardExpire": "", "phone": ""},
    {"name": "欧又菱", "cardType": "NI", "cardNo": "110113201801183796", "birthday": "2018-01-18", "cardExpire": "", "phone": ""},
    {"name": "章灵雁", "cardType": "NI", "cardNo": "310110201709105443", "birthday": "2017-09-10", "cardExpire": "", "phone": ""},
    {"name": "邓冰夏", "cardType": "NI", "cardNo": "220581201805104905", "birthday": "2018-05-10", "cardExpire": "", "phone": ""},
    {"name": "梁兴", "cardType": "NI", "cardNo": "451122201807120011", "birthday": "2018-07-12", "cardExpire": "", "phone": ""},
    {"name": "颜东", "cardType": "NI", "cardNo": "110106201706112427", "birthday": "2017-06-11", "cardExpire": "", "phone": ""},
];

let randomPP = [
    {"name": "JIAN/WANG", "cardType": "PP", "cardNo": "E13043934", "birthday": "1988-05-14", "cardExpire": "2026-12-09", "phone": ""},
    {"name": "QIANG/ZHANG", "cardType": "PP", "cardNo": "E34543247", "birthday": "1991-09-23", "cardExpire": "2025-07-04", "phone": ""},
    {"name": "LIWEI/SUN", "cardType": "PP", "cardNo": "E45768764", "birthday": "1970-02-05", "cardExpire": "2024-10-11", "phone": ""},
    {"name": "HAIMIN/DENG", "cardType": "PP", "cardNo": "E88987655", "birthday": "1989-11-30", "cardExpire": "2027-09-12", "phone": ""},
    {"name": "SHAN/DAI", "cardType": "PP", "cardNo": "E23354365", "birthday": "1984-03-19", "cardExpire": "2025-12-24", "phone": ""},
];

export default {randomAdult,randomChild,randomPP};