import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import { Paper, Tab, Tabs } from "@material-ui/core";

import Config from './Config';
import Auto from './Auto';
import Account from "./Account";

import apis from "apis";
import { Loading } from "components";


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

function TabPanel(props) {
  const { children, value, index } = props;
  return value === index ? children : null;
}

function Developer() {
  const classes = useStyles();
  const history = useHistory();
  const [agent, setAgent] = useState({});

  let initValue = Number(localStorage.getItem("agentDevelopTabIndex")) || 0;
  const [value, setValue] = useState(initValue);
  useEffect(() => {
    localStorage.setItem("agentDevelopTabIndex", value + "");
  }, [value])


  useEffect(() => {
    apis.agentGet({})
      .then(res => {
        if (res.status === 0) {
          setAgent(res.data);
        } else {
          // 如果走到了一个不存在的项目下，那么需要404
          history.push("/not-found");
        }
      })
      .catch(e => {
        console.log(e);
      });
  }, [history]);


  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Paper className={classes.root}>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, val) => {
              setValue(val);
            }}
          >
            <Tab label="代理配置" />
            <Tab label="自助接入" />
            <Tab label="成员账户" />
          </Tabs>
          {
            agent.agent ?
              (<div className={classes.content}>
                <TabPanel value={value} index={0}>
                  <Config agent={agent} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Auto agent={agent} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Account agent={agent} />
                </TabPanel>
              </div>) :
              (<Loading />)
          }

        </Paper>
      </div>
    </div>
  );
}

export default Developer;
