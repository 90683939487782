import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from "react-router-dom";
import { Button, Card, CardActions, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Table } from 'views/common';
import { AppContext } from "adapter";
import VisibilityIcon from "@material-ui/icons/Visibility";
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    justifyContent: 'center'
  },
  tableButton: {
    marginRight: theme.spacing(1)
  },
}));

const DataTable = props => {
  const { className, data, total, pageState, setRefresh, onSelected, selectedItem, ...rest } = props;
  const [page, setPage] = pageState;
  const { user } = useContext(AppContext);

  const classes = useStyles();
  const history = useHistory();
  const [selected, setSelected] = useState([]);

    useEffect(() => {
        if (selectedItem && data) {
            let find = data.find((it) => it.sourceId === selectedItem);
            if (find) {
                setSelected([find.id])
            }
        }

    }, [selectedItem, data])

  const handlePageChange = (_, page) => {
    setPage(page);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <Table
          checkedKey={"id"}
          checkbox={!!onSelected}
          checked={selected}
          handleSelectOne={(event, item) => {
              if (selected.length > 0 && selected[0] === item) {
                  setSelected([])
                  !!onSelected && onSelected(null)
              } else {
                  setSelected([item])
                  !!onSelected && onSelected(data.find((it) => it.id === item))
              }
          }}
          data={data}
          columns={[
            {
              label: 'ID',
              key: 'id'
            },
            {
              label: '资源Id',
              key: 'sourceId'
            },
            {
              label: '资源描述',
              key: 'sourceDesc'
            },
            {
              label: '创建日期',
              render: (item) => moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')
            },
            {
              label: '操作',
              render: (item) => (
                user.isAdmin ? <Button
                  startIcon={<VisibilityIcon style={{ fontSize: 16 }} />}
                  size="small"
                  color="primary"
                  className={classes.tableButton}
                  onClick={() => {
                    history.push("/sourceDetail/" + item.sourceId);
                  }}
                  variant="contained">管理</Button>
                  : null
              )
            }
          ]}
        />
      </CardContent>
      <CardActions className={classes.actions}>
        <Pagination
          count={Math.ceil(total / 10) || 1}
          page={page}
          onChange={handlePageChange}
          shape="rounded" />
      </CardActions>
    </Card>
  );
};

DataTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  onSelected: PropTypes.func
};

export default DataTable;
